import React from 'react';
import Section from 'components/utils/section';

// loading the sass style for the component
import css from './styles.module.scss';

/**
 * Molecule NewsAbstract
 *
 * <!-- TODO: add a description here! -->
 */
function NewsAbstract(props) {
  const { abstractItems } = props;

  return (
    <Section className={css['molecule__news-abstract-container']}>
      {abstractItems && (
        <ul className={css['abstract']}>
          {abstractItems.map((item, index) => (
            <li key={index}><span className={css['trace']}>-</span> {item.abstract}</li>
          ))}
        </ul>
      )}
    </Section>
  );
}

export default NewsAbstract;
