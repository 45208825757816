import React, { useEffect, useState } from 'react';
import CategoryTabs from 'components/molecules/category-tabs';
import TabCarCarousel from 'components/molecules/tab-car-carousel';

import Section from 'components/utils/section';
import Title from 'components/atoms/title';
import Wrapper from 'components/atoms/wrapper';
import vehicleUtils from 'utils/repo/vehicle';

import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

function TabsWrapper(props) {
  const contentTranslations = i18n.useTranslations('components.action_banner');

  const { className = '', carCategoriesData = null, locale, disclaimer, title = null, hidePrice, ...other } = props;

  const initialData = mountCarCategoriesData();

  const [selectedTab, setSelectedTab] = useState(initialData.tabs?.[0]?.id);
  const [carCategoriesTabs, setCarCategoriesTabs] = useState(initialData.tabs);
  const [carCategoriesContent, setCarCategoriesContent] = useState(initialData.contents);

  useEffect(() => {
    if (carCategoriesData && !carCategoriesTabs) {
      const initialData = mountCarCategoriesData();
      setCarCategoriesTabs(initialData.tabs);
      setCarCategoriesContent(initialData.contents);
    }
  }, [carCategoriesData]);

  function mountCarCategoriesData() {
    const tabs = [];
    const contents = [];

    carCategoriesData?.map((category) => {
      const modelContents = [];
      const hasAvailableModels = category?.models?.some((model) => model?.mainVersion?.modelYear?.available);

      if (hasAvailableModels) {
        tabs.push({
          id: category?.id,
          label: category?.name
        });
      }

      category?.models?.map((model) => {
        const medias = vehicleUtils.getMediaByType(model, 'main', 'display');
        const mediaRegular = medias.find((media) => media?.tag?.includes(':regular'));
        const mediaHover = medias.find((media) => media?.tag?.includes(':hover'));

        if (model?.mainVersion?.modelYear?.available) {
          modelContents.push({
            carlabel: model?.name,
            button: {
              label: contentTranslations('explore'),
              url: `${model?.page?.fullpath}`
            },
            link: !model?.removeFromBuildCar
              ? {
                  label: contentTranslations('build_your'),
                  url: locale === 'pt-BR' ? `${model?.page?.fullpath}/monte` : model.vehicleExternalUrl || null
                }
              : null,
            image: [
              {
                url: mediaRegular?.media?.url || null,
                alt: mediaRegular?.media?.title || model?.name,
                type: mediaRegular?.media?.mediaType?.title || null
              },
              {
                url: mediaHover?.media?.url || null,
                alt: mediaHover?.media?.title || model?.name,
                type: mediaHover?.media?.mediaType?.title || null
              }
            ],
            price: !hidePrice ? model?.mainVersion?.modelYear?.vehicleColors[0]?.price : null
          });
        }
      });

      contents.push({ id: category.id, content: modelContents });
    });

    return { tabs, contents };
  }

  function updateTab(id) {
    if (selectedTab !== id) {
      carCategoriesContent?.find((data) => data?.id === id && data?.content?.length > 0) ? setSelectedTab(id) : null;
    }
  }

  return (
    <Wrapper spacing="8" {...other}>
      <Section>
        {title && (
          <Title className={css['title']} level={2} variant={2}>
            {title}
          </Title>
        )}
        <div className={`${css['organism__tabs-wrapper-container']} ${className}`} {...other}>
          {carCategoriesTabs && (
            <CategoryTabs
              tabs={carCategoriesTabs}
              className={css['tabs-wrapper']}
              centered={true}
              onChange={updateTab}
              removeDesktopCarousel={true}
            />
          )}
          {carCategoriesContent &&
            selectedTab &&
            carCategoriesContent?.map((tab) => {
              return (
                <TabCarCarousel
                  locale={locale}
                  key={tab.id}
                  content={tab.content}
                  className={selectedTab === tab.id ? null : css['hidden']}
                />
              );
            })}
        </div>
      </Section>
      {disclaimer && <p className={css['tabs-wrapper-disclaimer']}>{disclaimer}</p>}
    </Wrapper>
  );
}

export default TabsWrapper;
