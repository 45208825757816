import { PageBuilder, buildComponent } from 'utils/page/page-builder';
import { PageProvider } from 'providers/page';
import { useRouter } from 'next/router';
import i18n from 'utils/i18n';
import PageMetaContent from 'components/atoms/page-meta-content';
import Header from 'components/organisms/header';
import React from 'react';
import Menu from 'utils/repo/menu';
import PageRepo from 'utils/repo/page';
import Repo from 'utils/repo';
import Logger from 'utils/repo/logger';
import getConfig from 'next/config';
import { buildTemplate } from 'utils/page/templates/functions';
import { PaginationTemplate } from 'utils/page/templates/PaginationTemplate';

const { serverRuntimeConfig } = getConfig();
const slugsCached = {};

const dataFetcher = async (query, variables = {}) => {
  const useCache = false;
  try {
    return await Repo.get(query, variables, useCache);
  } catch (error) {
    Logger.error('Error fetching data with query:', query, error);
    return { error: true, message: error.message };
  }
};

export default function GenericPage(props) {
  const router = useRouter();

  if (router.isFallback) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh',
          background: '#000'
        }}
      >
        {/* TODO: change this image */}
        <img src="https://phoneky.co.uk/thumbs/screensavers/down/technology/gpsspeedom_CpJHwN9Y.gif" alt="" />
      </div>
    );
  }

  const components = [];

  if (props?.pageBlocks) {
    props.pageBlocks.forEach((tuple, idx) => {
      components.push(buildComponent(tuple.component, `dynamic-component-${idx}`, tuple.props, tuple?.children || []));
    });
  }

  return (
    <>
      <PageMetaContent {...props} />
      <Header menuData={props?.menuData || []} fullpath={props?.fullpath} />
      <PageProvider>
        <main id="main">{components}</main>
      </PageProvider>
    </>
  );
}

/**
 * Builder for breadcrumb during full build rountine (when all pages are
 * rendered at once).
 *
 * @param {string} current Fullpath of current rendenring page
 * @returns Array of objects with slug and name
 */
function breadcrumbBuilderFromPath(current) {
  const crumbs = [];

  // eslint-disable-next-line no-constant-condition
  while (true) {
    current = current.endsWith('/') && current.length > 0 ? current.slice(0, -1) : current;

    if (current.length === 0 || !current.includes('/')) {
      break;
    }

    crumbs.push(slugsCached[current]);

    let parts = current.split('/');
    parts.pop();
    current = parts.join('/');
  }

  crumbs.push({ slug: '/', name: 'Home', id: '' });

  return crumbs.reverse();
}

/**
 * Builder for breadcrumb during SSR or ISR rendering of one page
 *
 * @param {Page} page Object with data of current page
 * @return Promise<Array>
 */
async function breadcrumbBuilderFromTree(page) {
  let crumbs = [];
  let pages = [];

  try {
    const breadcrumbResult = await Repo.get(PageRepo.findPageTree(), { id: page.id });
    if (!breadcrumbResult?.data?.pageTree?.length) {
      return [];
    }

    pages = breadcrumbResult?.data?.pageTree || [];
  } catch (err) {
    Logger.debug('[Page::breadcrumb] Failed to get page tree with query. Reason:', err);

    return [];
  }

  crumbs = pages.map((entry) => {
    return {
      slug: entry.fullpath,
      name: entry.title,
      id: entry.id
    };
  });

  crumbs.push({ slug: '/', name: 'Home', id: '' });

  return crumbs.reverse();
}

/**
 *
 * @param {Page} page
 */

export async function getStaticPaths({ locales }) {
  // By limitation of Amplify, we can only have 1 locale each time (installation)
  // So we can assume first locale as valid for get paths
  const productId = serverRuntimeConfig.PRODUCT_ID;
  // find all disponible pages
  const pagesResult = await Repo.get(PageRepo.findAllByProduct(), { productId });
  let pages = pagesResult?.data?.pages || [];
  let paths = [];

  if (!pages.length) {
    return { paths, fallback: true };
  }

  // make sure to process parent pages before its childrens
  pages.sort((a, b) => ('' + a.fullpath).localeCompare(b.fullpath));

  // map the paths
  for (const page of pages) {
    slugsCached[page.fullpath] = {
      slug: page.fullpath,
      name: page.title,
      id: page.id
    };

    let slug = page.fullpath.startsWith('/') ? page.fullpath.substring(1) : page.fullpath;
    const templateName = page?.template || 'PageTemplate';
    const template = buildTemplate(templateName);
    const isPageable = template instanceof PaginationTemplate;

    const props = {
      params: {
        slug: slug.split('/'),
        breadcrumb: breadcrumbBuilderFromPath(page.fullpath)
      }
    };

    paths.push(props);

    if (isPageable) {
      let totalPages = (await template.getTotalPages(dataFetcher)) || 1;

      for (let i = 1; i <= totalPages; i++) {
        const pageSlug = slug.split('/');
        pageSlug.push(i.toString());
        paths.push({
          params: {
            slug: pageSlug,
            breadcrumb: breadcrumbBuilderFromPath(page.fullpath)
          }
        });
      }
    }
  }

  return { paths, fallback: true };
}

export async function getStaticProps({ locale, params }) {
  const productId = serverRuntimeConfig?.PRODUCT_ID || '';
  params = structuredClone(params);

  let slug = Array.isArray(params?.slug) ? params.slug.join('/') : params?.slug || '/';
  slug = slug.charAt(0) !== '/' ? `/${slug}` : slug;

  let paginationParts = slug.match(/\/[0-9]+$/);
  let totalPages = 1;
  let currentPage = 1;
  let page = false;

  if (paginationParts) {
    slug = slug.split('/').slice(0, -1).join('/');
    currentPage = parseInt(paginationParts[0].substring(1), 10);
  }

  try {
    const pageResult = await Repo.get(PageRepo.findBySlug(), { fullpath: slug, productId: productId });
    page = pageResult?.data?.pageByPathAndProduct || false;
  } catch (err) {
    Logger.error(`[getStaticProps] Error fetching page data for slug '${slug}':`, err);
  }

  const templateName = page?.template || 'PageTemplate';
  const template = buildTemplate(templateName);
  const isPageable = template instanceof PaginationTemplate;

  if (isPageable) {
    try {
      totalPages = (await template.getTotalPages(dataFetcher)) || 1;
      params.totalPages = parseInt(totalPages);
      params.currentPage = parseInt(currentPage);
    } catch (err) {
      page = false;
      Logger.error(`[getStaticProps] Error fetching total pages for slug '${slug}':`, err);
    }
  }

  const pageNotInthePaths = isPageable && currentPage > parseInt(totalPages);

  // if nothing is found or status is not published, show the 404 page

  if (!page || page.status !== 'PUBLISHED' || pageNotInthePaths) {
    Logger.debug(
      `[Page::staticProps] Skip the generation of page '${slug}' at '${locale}'. Reason: empty page or not published`,
      { ...page }
    );

    return {
      redirect: {
        destination: '/404',
        permanent: false
      }
    };
  }

  const menuData = await Menu.getMenuItemsByType({ type: 'main', productId });
  params.breadcrumb = await breadcrumbBuilderFromTree(page);

  try {
    const i18nMessages = await i18n.getMessages(locale);
    const pageBuilder = new PageBuilder(page, Repo);

    const pageBlocks = await pageBuilder.page({ params, locale, productId, i18nMessages });
    if (!pageBlocks) {
      Logger.error(`[Page::staticProps] Failed to generate page '${slug}' at '${locale}'. Reason: empty pageBlocks`);

      return {
        redirect: {
          destination: '/404',
          permanent: false
        }
      };
    }

    const titleConfig = page?.configs?.find((c) => c.name === 'title');

    return {
      revalidate: serverRuntimeConfig.REVALIDATION_INTERVAL, // ISR: just revalidate at given interval
      props: removeUndefineds({
        ...page,
        messages: i18nMessages,
        pageBlocks,
        menuData,
        title: titleConfig?.value || page?.title
      })
    };
  } catch (err) {
    Logger.error(`[Page::staticProps] Failed to generate page '${slug}' at '${locale}'. Reason: `, err);

    return {
      redirect: {
        destination: '/404',
        permanent: false
      }
    };
  }
}

function removeUndefineds(obj) {
  // cleaning an array
  if (Array.isArray(obj)) {
    const arr = obj;
    const newArr = [];
    arr.forEach((val, key) => {
      if (typeof val === 'undefined') {
        return;
      }
      if (val === Object(val)) {
        // this is an object, not a regula value
        newArr[key] = removeUndefineds(val);
      } else {
        newArr[key] = val;
      }
    });
    return newArr;
  }

  // cleaning an object
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    if (typeof val === 'undefined') {
      return;
    }
    if (val === Object(val)) {
      // this is an object, not a regula value
      newObj[key] = removeUndefineds(val);
    } else {
      newObj[key] = val;
    }
  });
  return newObj;
}
