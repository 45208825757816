import React, { useEffect, useRef, useState } from 'react';
import i18n from 'utils/i18n';
import { removeSpecialCharacters } from 'utils/forms';
import { getVehiclesForCustomer } from 'utils/functions/appointment';
import { isChassiValid } from 'utils/validations';

import Button from 'components/atoms/button';
import CardMyVehicleDetails from 'components/molecules/card-my-vehicle-details';
import DeleteConfirmationModal from 'components/molecules/delete-confirmation-modal';
import FormInput from 'components/atoms/form-input';
import LoaderOverlay from 'components/molecules/loader-overlay';
import Modal from 'components/molecules/modal';
import Notification from 'components/molecules/notification';
import Title from 'components/atoms/title';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMediaQuery } from 'react-responsive';

import css from './styles.module.scss';

function MyVehicle(props) {
  const t = i18n.useTranslations('components.my-vehicle');

  const { className = '', children, ...other } = props;

  const [viewChassi, setViewChassi] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedVehicleToDelete, setSelectedVehicleToDelete] = useState(null);
  const [listMyVehicles, setListMyVehicles] = useState([]);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [notificationKey, setNotificationKey] = useState(0);

  const messageRef = useRef(null);
  const lastVehicleRef = useRef(null);
  const vehicleContainerRef = useRef(null);

  const [notificationType, setNotificationType] = useState(null);

  const [notificationData, setNotificationData] = useState({
    content: '',
    title: '',
    type: '',
    isOpen: false
  });

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const scrollToNotification = () => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToFirstVehicle = () => {
    if (vehicleContainerRef.current) {
      const firstVehicle = vehicleContainerRef.current.firstChild;
      if (firstVehicle) {
        firstVehicle.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const scrollToLastVehicle = () => {
    if (vehicleContainerRef.current) {
      const lastVehicle = vehicleContainerRef.current.lastChild;
      if (lastVehicle) {
        lastVehicle.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const fetchAllMyVehicles = async () => {
    setLoading(true);
    try {
      const vehicles = await getVehiclesForCustomer();
      setListMyVehicles(vehicles);
    } catch (error) {
      console.error(`Failed to fetch and set vehicles: ${error?.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllMyVehicles();
  }, []);

  const validationSchemaConfig = {
    chassi: yup
      .string()
      .required(t('message_error_required_chassis'))
      .test('is-17-digits', t('message_error_invalid_chassis_length'), (value) => {
        return value && value.length === 17;
      })
      .test('no-special-characters', t('message_error_invalid_chassis_characters'), (value) => {
        return /^[A-Za-z0-9]+$/.test(value);
      }),
    patent: yup.string().test('patent-validation', t('message_error_invalid_patent_characters'), (value) => {
      return !value || /^[A-Za-z0-9]+$/.test(value);
    })
  };

  const validationSchema = yup.object().shape(validationSchemaConfig);

  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema)
  });

  const generateMessage = (action, actionType) => {
    let titleKey, contentKey;

    if (actionType === 'error') {
      titleKey = `form_submit_title_error_${action}`;
      contentKey = `form_submit_content_error_${action}`;
    } else {
      titleKey = `form_submit_title_success_${action}`;
      contentKey = `form_submit_content_success_${action}`;
    }

    const title = t(titleKey);
    const content = t(contentKey) || '';

    return {
      title,
      content,
      type: actionType,
      isOpen: true
    };
  };

  const handleNotification = (action, actionType) => {
    setNotificationKey(notificationKey + 1);
    setNotificationType({ action, actionType });
    setNotificationData(generateMessage(action, actionType));
    scrollToNotification();
  };

  const addNewVehicle = async (data) => {
    try {
      setIsSubmitting(true);
      const opts = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      const url = '/api/my-vehicle/create-customer-vehicle';

      const response = await fetch(url, opts);

      if (!response.ok) {
        handleNotification('add', 'error');
        throw new Error('Request error. Please try again later.');
      }

      if (response?.status === 200) {
        const responseData = await response.json();

        if (typeof responseData !== 'object' || responseData === null) {
          handleNotification('add', 'error');
          throw new Error('Invalid response format. Please try again later.');
        }

        if (Object.keys(responseData).length === 0) {
          handleNotification('add', 'error');
          throw new Error('Empty response. Please check your input.');
        }

        if (responseData && Object.keys(responseData).length > 0) {
          const currentCustomerVehicleId = responseData?.data?.createCustomerVehicle?.id;
          if (currentCustomerVehicleId) {
            handleNotification('add', 'success');
            reset();

            setTimeout(() => {
              handleCloseNotification();
              fetchAllMyVehicles();
            }, 2000);

            return responseData;
          }
        }
      } else {
        handleNotification('add', 'error');
        throw new Error(`Request failed with status ${response.status}`);
      }
    } catch (error) {
      console.log(error);
      handleNotification('add', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteVehicle = async (vehicleId) => {
    try {
      const opts = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(vehicleId)
      };

      const url = `/api/my-vehicle/delete-customer-vehicle`;

      const response = await fetch(url, opts);

      if (!response.ok) {
        handleNotification('delete', 'error');
        throw new Error('Request error. Please try again later.');
      }

      if (response?.status === 200) {
        const responseData = await response.json();

        if (
          responseData &&
          responseData.data?.deleteCustomerVehicle?.message === 'Customer Vehicle deleted successfully'
        ) {
          setOpenModalDelete(false);
          handleNotification('delete', 'success');
          setIsDeleting(true);

          setTimeout(() => {
            setIsDeleting(false);
            handleCloseNotification();
            fetchAllMyVehicles();
          }, 2000);
        } else {
          setOpenModalDelete(false);
          handleNotification('delete', 'error');
          throw new Error('Failed to delete vehicle. Please try again.');
        }
      } else {
        setOpenModalDelete(false);
        handleNotification('delete', 'error');
        throw new Error(`Request failed with status ${response.status}`);
      }
    } catch (error) {
      console.log(error);
      setOpenModalDelete(false);
      handleNotification('delete', 'error');
    }
  };

  const closeModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleCloseNotification = () => {
    setNotificationData({
      ...notificationData,
      isOpen: false
    });

    if (notificationType?.actionType === 'success') {
      if (notificationType?.action === 'add') {
        scrollToLastVehicle();
      } else if (notificationType?.action === 'delete') {
        scrollToFirstVehicle();
      }
    }
  };

  const onSubmit = async (data) => {
    if (isChassiValid(data.chassi)) {
      await addNewVehicle(data);
    }
  };

  const onError = (error) => {
    console.log('Error: ', error);
  };

  const onClearFields = () => {
    reset();
  };

  return (
    <div className={`${css['molecule__my-vehicle-container']} ${className}`} {...other}>
      {loading && <LoaderOverlay content={t('content_loading')} />}
      <Title className={css['title-my-vehicle']} variant="2">
        {t('section_title')}
      </Title>

      {listMyVehicles?.length === 0 && !isDeleting && (
        <div className={css['container-content-info']}>
          <Title className={css['container-content-info-title']} variant="6">
            {t('form_title')}
          </Title>
          <p className={css['container-content-info-text']}>{t('form_sub_title')}</p>
        </div>
      )}
      {listMyVehicles?.length > 0 && !isDeleting && (
        <div className={css['my-vehicle-container-details']} ref={vehicleContainerRef}>
          {listMyVehicles?.map((vehicle, index) => (
            <div key={index}>
              <CardMyVehicleDetails
                ref={index === listMyVehicles?.length - 1 ? lastVehicleRef : null}
                vehicle={vehicle}
                onOpenModalDelete={() => {
                  setSelectedVehicleToDelete(vehicle);
                  setOpenModalDelete(true);
                }}
              />
            </div>
          ))}
        </div>
      )}

      <form className={css['container-form']} onSubmit={handleSubmit(onSubmit, onError)}>
        {listMyVehicles?.length > 0 && (
          <Title className={css['title-new-vehicle']} variant="2">
            {t('form_title-new-vehicle')}
          </Title>
        )}
        <div className={css['container-form-fields']}>
          <FormInput
            className={css['form-input']}
            id="chassi"
            type="text"
            label={t('label_chassis')}
            placeholder={t('placeholder_chassis')}
            dataRegister={register('chassi')}
            dataErrors={errors['chassi']}
            maxLength="17"
            displayLabel={true}
            isLongMessage={true}
            masking={(event) => {
              const value = event.target.value;
              event.target.value = removeSpecialCharacters(value);
            }}
          />
          <FormInput
            className={css['form-input']}
            id="patent"
            type="text"
            label={t('label_patent')}
            placeholder={t('placeholder_patent')}
            dataRegister={register('patent')}
            dataErrors={errors['patent']}
            displayLabel={true}
            isLongMessage={true}
            masking={(event) => {
              const value = event.target.value;
              event.target.value = removeSpecialCharacters(value);
            }}
          />
        </div>

        <Button className={css['container-form-button-modal']} type="button" onClick={() => setViewChassi(true)}>
          <label>{t('button_modal_label')}</label>
        </Button>

        <div className={css['container-form-button-submit']}>
          <Button className={css['button-submit']} disabled={!formState.isValid || isSubmitting} type="submit">
            {t('button_submit_label')}
          </Button>
          <Button
            color="ghost-2"
            className={css['button-cancel']}
            onClick={onClearFields}
            disabled={!formState.isValid}
            type="button"
          >
            {t('button_cancel_label')}
          </Button>
        </div>
      </form>

      <div className={css['container-notification']} ref={messageRef}>
        {notificationData.isOpen && (
          <Notification
            key={notificationKey}
            fullScreen={true}
            contentNotification={notificationData.content}
            titleNotification={notificationData.title}
            typeNotification={notificationData.type}
            isOpen={notificationData.isOpen}
            handleClose={handleCloseNotification}
          />
        )}
      </div>

      {openModalDelete && selectedVehicleToDelete && (
        <Modal
          title=" "
          modalIsOpen={openModalDelete}
          onClose={closeModalDelete}
          className={css['delete-modal']}
          modalWidth="auto"
          modalHeight={'max-content'}
        >
          <DeleteConfirmationModal
            vehicle={selectedVehicleToDelete}
            title={t('title-modal-delete')}
            content={t('content-modal-delete')}
            onCancel={closeModalDelete}
            onConfirmDelete={deleteVehicle}
          />
        </Modal>
      )}

      {viewChassi && (
        <Modal
          title=" "
          modalIsOpen={viewChassi}
          onClose={() => setViewChassi(false)}
          className={css['chassi-modal']}
          modalWidth="auto"
          modalHeight={isMobile ? '348px' : '90%'}
        >
          <img
            style={{
              width: '100%',
              objectFit: 'cover'
            }}
            src="/images/chassiAR.png"
            alt="chassi"
          />
        </Modal>
      )}

      {children}
    </div>
  );
}

export default MyVehicle;
