/* eslint-disable @typescript-eslint/no-explicit-any */
import Service from 'utils/repo/service';
import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class MaintenancePlan implements ContentBlock {
  label = 'Maintenance Plan';

  component = 'MaintenancePlan';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'textTransform',
      label: 'The text tranform',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        'default',
        'capitalize',
        'uppercase'
      ]
    }
  ];

  properties = [];

  async adapter(contents: any, extra: any, dataFetcher: any) {
    const serviceRequestParams = {
      pageId: extra?.context?.page?.id
    };
    return {
      textTranform: extra?.settings?.textTranform,
      serviceItemCategories:
        (await dataFetcher(Service.getServiceItemCategoriesByPage(), serviceRequestParams))?.data?.serviceByPage
          ?.serviceItemCategories || [],
      locale: extra?.context?.locale || 'pt-BR'
    };
  }
}

export { MaintenancePlan };
