import React, { useState, useEffect, useRef } from 'react';
import CloseButton from 'components/atoms/close-button';
import AnchorLink from 'components/atoms/anchor-link';
import JSZip from 'jszip';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule GalleryModal
 *
 * <!-- TODO: add a description here! -->
 */
function GalleryModal(props) {
  const t = i18n.useTranslations('components.gallery-modal');

  const {
    className = '',
    dataGallery = null,
    setOpenModal = null,
    isDownload = false,
    hasArrow = false,
    initialIndex,
    backgroundModal = 'dark',
    ...other
  } = props;

  const [currentImage, setCurrentImage] = useState(!initialIndex ? 0 : initialIndex);
  const modalRef = useRef(null);

  const handleNextImage = () => {
    if (currentImage + 1 === dataGallery.length) {
      setCurrentImage(0);
    } else {
      setCurrentImage(currentImage + 1);
    }
  };

  const handlePreviousImage = () => {
    if (currentImage === 0) {
      setCurrentImage(dataGallery.length - 1);
    } else {
      setCurrentImage(currentImage - 1);
    }
  };

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        handleNextImage();
      } else if (event.key === 'ArrowLeft') {
        handlePreviousImage();
      } else if (event.key === 'Escape') {
        setOpenModal(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentImage]);

  const handleDownloadImage = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
      }
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = url.substring(url.lastIndexOf('/') + 1);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const handleDownloadAllImages = async () => {
    if (dataGallery && dataGallery.length > 0) {
      const zip = new JSZip();
      const folder = zip.folder('images');

      for (const image of dataGallery) {
        try {
          const response = await fetch(image.url);
          if (!response.ok) {
            throw new Error(`Failed to fetch ${image.url}: ${response.statusText}`);
          }
          const blob = await response.blob();
          const fileName = image.url.substring(image.url.lastIndexOf('/') + 1);
          folder.file(fileName, blob);
        } catch (error) {
          console.error('Error downloading image:', error);
        }
      }

      try {
        const content = await zip.generateAsync({ type: 'blob' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(content);
        link.download = 'images.zip';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error generating ZIP file:', error);
      }
    }
  };

  const imageHandler = () => {
    if (dataGallery) {
      if (dataGallery[currentImage].url) {
        if (dataGallery[currentImage].alt) {
          return (
            <img
              src={dataGallery[currentImage].url}
              alt={dataGallery[currentImage].alt}
              className={css['image-over']}
            />
          );
        }
        return (
          <div className={css['modal-image-container']}>
            {isDownload && (
              <div className={css['number-image']}>
                <span>{`${currentImage + 1}/${dataGallery.length}`}</span>
              </div>
            )}
            <img src={dataGallery[currentImage].url} alt="gallery" className={css['image-over']} />
            {isDownload && (
              <div className={css['download-links']}>
                <div className={css['download-current-image-at']}>
                  <p>{t('download-current-image-at')}</p>
                  <p>
                    <AnchorLink
                      hasArrow={hasArrow}
                      className={css['mos-link']}
                      size="small"
                      linkLabel={t('low-resolution')}
                      link="#"
                      onClick={() => handleDownloadImage(dataGallery[currentImage].url)}
                    />

                    {dataGallery[currentImage].urlHighResolution && (
                      <>
                        <span className={css['spacer']}>{'|'}</span>
                        <AnchorLink
                          hasArrow={hasArrow}
                          className={css['mos-link']}
                          size="small"
                          linkLabel={t('high-resolution')}
                          link="#"
                          onClick={() => handleDownloadImage(dataGallery[currentImage].urlHighResolution)}
                        />
                      </>
                    )}
                  </p>
                </div>
                <div className={css['download-all-images-at']}>
                  <p>{t('download-all-images-at')}</p>
                  <p>
                    <AnchorLink
                      hasArrow={hasArrow}
                      className={css['mos-link']}
                      size="small"
                      linkLabel={t('low-resolution')}
                      link="#"
                      onClick={handleDownloadAllImages}
                    />
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      }
    }
  };

  return (
    <div
      className={`${css['molecule__gallery-modal-container']} ${className}`}
      {...other} background-modal={backgroundModal}
    >
      <div className={css['gallery-wrapper']} ref={modalRef}>
        {imageHandler()}
        <CloseButton color="white" className={css['close-button']} onClick={() => setOpenModal(false)} />
        <button className={css['arrowButtonPrev']} onClick={handlePreviousImage}>
          <div className={css['arrow-left']} />
        </button>
        <button className={css['arrowButtonNext']} onClick={handleNextImage}>
          <div className={css['arrow-right']} />
        </button>
      </div>
    </div>
  );
}

export default GalleryModal;
