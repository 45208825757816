import { getCurrencySymbol } from '.';

export const formatPriceAppointment = (price, locale, bonusText) => {
  if (price === 0 && bonusText) {
    return bonusText;
  }

  const currencySymbol = getCurrencySymbol(locale);

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencySymbol
  });

  const formattedPrice = formatter.format(price);
  return `${formattedPrice}¹`;
};

export const getVehiclesForCustomer = async () => {
  try {
    const response = await fetch(`/api/my-vehicle/customer-vehicles`);

    if (!response.ok) {
      throw new Error(`Failed to fetch customer vehicles: ${response.status} - ${response.statusText}`);
    }

    const responseData = await response.json();

    if (responseData && Array.isArray(responseData.MyVehicle)) {
      return responseData.MyVehicle;
    } else {
      throw new Error('API response is not in the expected format.');
    }
  } catch (error) {
    console.error(`An error occurred: ${error?.message}`);
    throw error;
  }
};
