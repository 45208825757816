import Title from 'components/atoms/title';
import React from 'react';
import ExpandedContent from 'components/molecules/expanded-content';
import ReactMarkdown from 'react-markdown';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';
import { IconShare, IconWhatsApp } from 'components/icons';
import Button from 'components/atoms/button';
import { urlHttpCheck } from 'utils/functions';
import rehypeRaw from 'rehype-raw';
import { isNumberMask } from 'utils/forms';

/**
 * Molecule FaqList
 *
 * <!-- TODO: add a description here! -->
 */
function FaqList(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.faq-list');

  const {
    className = '',
    title = null,
    faqContent = {},
    labelSize = null,
    invertArrow = false,
    type = null,
    locale = 'pt-BR',
    ...other
  } = props;

  const urlWhats = t('url_whatsapp');
  const textWhats = t('text_whatsapp');
  const mailto = 'mailto:';

  const { items } = faqContent;

  const isTDV = locale?.toLowerCase() === 'es-ve';

  const getSocialIcon = (type) => {
    const iconSrc = `/images/social-media-icons/${type}.svg`;
    const altText = `${type.charAt(0).toUpperCase() + type.slice(1)} Icon`;
    return <img src={iconSrc} alt={altText} />;
  };

  return (
    <div className={`${css['faq-list-container']} ${className}`} {...other} data-type={type}>
      {title && (
        <Title level={2} variant={2} className={css['faq-list-title']}>
          {title}
        </Title>
      )}

      <ul className={css['faq-list-container']}>
        {items?.map((item, index) => (
          <li key={`faq-list-item-${index}`} className={css['faq-list__item']}>
            <ExpandedContent invertArrow={invertArrow} labelSize={labelSize} label={item.title}>
              {Array.isArray(item.content) ? (
                <div className={css['faq-list__items-container']}>
                  {item.content.map((content, contentIndex) => (
                    <div
                      key={`faq-list-item-container-${index}-${contentIndex}`}
                      className={css['faq-list__item-container']}
                    >
                      <div className={css['faq-list__item-infos']}>
                        {content.title && <span className={css['faq-list__item-title']}>{content.title}</span>}
                        {content.companyName && locale === 'es-AR' && (
                          <span className={css['faq-list__item-info']}>Razón Social: {content.companyName}</span>
                        )}
                        {content.cuit && locale === 'es-AR' && (
                          <span className={css['faq-list__item-info']}>CUIT: {content.cuit}</span>
                        )}
                        {content.description && (
                          <span className={css['faq-list__item-info']}>{content.description}</span>
                        )}
                        {content.street && (
                          <span className={css['faq-list__item-info']}>
                            {content.street}, {content.city}
                          </span>
                        )}
                        {content.state && (
                          <span className={css['faq-list__item-info']}>
                            {content.state} {locale === 'pt-BR' && `, ${content.uf}, CEP ${content.zipcode}`}
                          </span>
                        )}
                        {content.contactEmail && (
                          <a
                            href={mailto.concat(content?.contactEmail)}
                            className={`${css['faq-list__item-info']} ${css['tiny-text']}`}
                          >
                            {content.contactEmail}
                          </a>
                        )}
                        {content.site && (
                          <a
                            href={urlHttpCheck(content.site)}
                            className={`${css['faq-list__item-info']} ${css['tiny-text']}`}
                          >
                            {content.site}
                            <div className={css['icon-container']}>
                              <IconShare />
                            </div>
                          </a>
                        )}
                        {(content.phone || content.whatsapp) && (
                          <div className={`${css['faq-list__item-infos']} ${css['tiny-text']}`}>
                            {content.phone && <span>{content.phone}</span>}
                            {content.whatsapp && (
                              <span className={`${css['faq-list__item-info']} ${css['tiny-text']}`}>
                                {content.whatsapp}
                                <a
                                  href={urlWhats.concat(isNumberMask(content.whatsapp)).concat(textWhats)}
                                  className={css['icon-container']}
                                >
                                  <IconWhatsApp />
                                </a>
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                      {isTDV && content?.socialMedias?.length > 0 && (
                        <div className={css['faq-list__item-medias']}>
                          {content?.socialMedias.map((social, index) => (
                            <a key={index} target="_blank" href={urlHttpCheck(social?.value)}>
                              {getSocialIcon(social.name)}
                            </a>
                          ))}
                        </div>
                      )}
                      {(content.phone || content.whatsapp) && content.city && content.state && (
                        <Button link={content.mapLink} color="ghost-2" className={css['faq-list__item-button']}>
                          {content.buttonLabel}
                        </Button>
                      )}
                      {content.more && <span className={css['faq-list__item-more']}>{content.more}</span>}{' '}
                    </div>
                  ))}
                </div>
              ) : (
                <div className={css['faq-list__item-text-container']}>
                  {/* eslint-disable-next-line */}
                  <ReactMarkdown className={css['faq-list__item-text']} rehypePlugins={[rehypeRaw]}>
                    {item?.content}
                  </ReactMarkdown>
                  {/* <span className={css['faq-list__item-text']}>{item.content}</span> */}
                </div>
              )}
            </ExpandedContent>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FaqList;
