import FormSelect from 'components/atoms/form-select';
import Title from 'components/atoms/title';
import React, { useEffect, useState } from 'react';
import FaqList from 'components/molecules/faq-list';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';
import { currency } from 'utils/functions';

/**
 * Molecule MaintenancePlan
 *
 * <!-- TODO: add a description here! -->
 */
function MaintenancePlan(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.maintenance-plan');

  const { className = '', locale = 'pt-BR', textTransform = 'default', serviceItemCategories = [], ...other } = props;

  const isTDB = locale === 'pt-BR';
  const currencySymbol = isTDB ? 'BRL' : locale === 'es-AR' ? 'ARS' : 'USD';

  const [serviceItemCategory, setServiceItemCategory] = useState({});
  const [serviceItem, setServiceItem] = useState({});
  const [items, setItems] = useState([]);

  let startValidity = '';
  if (serviceItem?.price?.startValidity) {
    startValidity = new Date(serviceItem?.price?.startValidity).toLocaleDateString(locale, { timeZone: 'GMT' });
  }

  let endValidity = '';
  if (serviceItem?.price?.endValidity) {
    endValidity = new Date(serviceItem?.price?.endValidity).toLocaleDateString(locale, { timeZone: 'GMT' });
  }

  useEffect(() => {
    let contents = [];
    serviceItem?.contents?.map((item) => {
      contents.push({
        title: item.title,
        content: item.description
      });
    });
    setItems(contents);
  }, [serviceItem]);

  return (
    <div data-text-transform={textTransform} className={`${css['maintenance-plan-container']} ${className}`} {...other}>
      <Title level={3} variant={3} className={css['module-title']}>
        {t('title')}
      </Title>

      <div className={css['select-image-container']}>
        <div className={css['select-container']}>
          <div className={css['select-with-image-container']}>
            <FormSelect
              label={t('first-select-label')}
              displayLabel={true}
              id="carModel"
              className={css['input-container']}
              placeholder={t('placeholder-first-select')}
              onChange={(e) => {
                setServiceItemCategory(serviceItemCategories?.[e?.target?.value]);
                setServiceItem({});
              }}
            >
              {serviceItemCategories?.length > 0 &&
                serviceItemCategories?.map((item, index) => {
                  return (
                    <option key={item?.id} value={index}>
                      {item?.title}
                    </option>
                  );
                })}
            </FormSelect>
          </div>
        </div>

        <div className={css['model-image-container']}>
          <img
            className={css['model-image-container__image']}
            src={serviceItemCategory?.media?.url}
            alt={serviceItemCategory?.media?.title}
          />
        </div>
      </div>

      {Object?.values(serviceItemCategory)?.length > 0 && (
        <div className={css['select-container']}>
          <FormSelect
            displayLabel={true}
            displayError={false}
            id="miles"
            label={t('second-select-label')}
            placeholder={t('placeholder-second-select')}
            className={css['input-container']}
            onChange={(e) => setServiceItem(serviceItemCategory?.serviceItems?.[e?.target?.value])}
          >
            {serviceItemCategory?.serviceItems?.length > 0 &&
              serviceItemCategory?.serviceItems?.map((item, index) => {
                return (
                  <option key={item?.id} value={index}>
                    {item?.title}
                  </option>
                );
              })}
          </FormSelect>
        </div>
      )}

      {Object?.values(serviceItem)?.length > 0 && (
        <>
          <ul className={css['payment-info-list-container']}>
            <li className={css['payment-info-list-container__item']}>
              <span className={css['item__label']}>{t('whole-price-label')}</span>
              <strong className={css['item__value']}>
                {currency(serviceItem?.price?.value, locale, currencySymbol)}
                <span className={css['payment-completion']}>{locale === 'es-AR' && ' (1, 3)'}</span>
              </strong>
            </li>

            <li className={css['payment-info-list-container__item']}>
              <span className={css['item__label']}>
                {t('parcel-price-label-part-one')}
                {serviceItem?.price?.installment?.quantity}
                {t('parcel-price-label-part-two')}
              </span>
              <strong className={css['item__value']}>
                {currency(serviceItem?.price?.installment?.value, locale, currencySymbol)}
                {locale === 'es-AR' && <span className={css['payment-completion']}>c/u (2, 3)</span>}
              </strong>
              {locale === 'es-AR' && (
                <span className={css['item__extra-info']}>
                  {t('parcel-price-text-part-one')}
                  {endValidity}
                  {t('parcel-price-text-part-two')}
                </span>
              )}
            </li>
          </ul>

          <Title level={2} variant={2}>
            {t('faq-title')}
          </Title>

          <FaqList faqContent={{ items }} />

          <div className={css['paragraph-container']}>
            <Title className={css['paragraph-container__text']}>{t('legal-title')}</Title>

            {isTDB ? (
              <p className={css['paragraph-container__text']}>
                Os preços relativos ao Plano de Manutenção têm validade de {startValidity} a {endValidity} em todo o
                território nacional (exceto para o estado do ES) e incluem as peças e lubrificantes genuínos Toyota,
                além de mão de obra da sua rede de concessionárias. A 1ª revisão tem mão de obra gratuita se realizada
                dentro de 12 meses ou 10.000 km após a compra (o que ocorrer primeiro). Caso seja excedido esse prazo,
                haverá o custo do pacote + valor de mão de obra à parte. A partir da 2ª revisão em diante – a ser
                realizada a cada 10.000 km ou 12 meses após a última revisão – o valor de mão de obra já está incluso no
                pacote. Para mais detalhes dos serviços prestados, consulte o Plano de Manutenção do veículo contido no
                manual do proprietário ou pelo site <a href="www.toyota.com.br"> www.toyota.com.br</a>.
              </p>
            ) : (
              <>
                <p className={css['paragraph-container__text']}>
                  (1) OFERTA VALIDA EN TODO EL TERRITORIO DE LA REPÚBLICA ARGENTINA PARA LA REALIZACIÓN DE SERVICIOS DE
                  MANTENIMIENTO ESTABLECIDOS DE ACUERDO CON EL PLAN DE MANTENIMIENTO TOYOTA, PARA TODAS LAS VERSIONES
                  DEL VEHICULO {serviceItemCategory?.title} LINEA ACTUAL EN CUALQUIERA DE LOS CONCESIONARIOS OFICIALES
                  DE LA RED TOYOTA DE LA REPÚBLICA ARGENTINA, HASTA EL {endValidity} INCLUSIVE. PRECIOS FINALES AL
                  CONSUMIDOR (IVA INCLUIDO). ORIGEN DE LOS VEHÍCULOS: {serviceItemCategory?.description}. NO INCLUYE
                  REPARACIONES NI TRABAJOS ADICIONALES, AJENOS AL MENCIONADO PLAN DE MANTENIMIENTO DE LA LINEA ACTUAL
                  DEL MODELO. PARA MÁS INFORMACIÓN Y CONDICIONES O LIMITACIONES APLICABLES, COMUNÍQUESE GRATUITAMENTE AL
                  TELÉFONO 0800-888-8696. Toyota Argentina S.A., Planta Industrial Zárate: Ruta 12, Km 81 (B28000FA)
                  Zárate - Bs. As. – Argentina, CUIT: 33-67913936-9
                </p>
                <p className={css['paragraph-container__text']}>
                  (2) PROMOCIÓN VÁLIDA DEL {startValidity} AL {endValidity} EN TODOS LOS CONCESIONARIOS OFICIALES DE LA
                  RED TOYOTA DE LA REPÚBLICA ARGENTINA, PARA LA CONTRATACIÓN DE SERVICIOS DE MANTENIMIENTO PERIÓDICO DE
                  VEHÍCULOS TOYOTA, SEGÚN PLAN DE MANTENIMIENTO TOYOTA, CON TARJETAS DE CRÉDITO VISA Y MASTERCARD
                  EMITIDAS POR CUALQUIER BANCO, EXCEPTUANDO LAS EMITIDAS POR BANCO SANTANDER, BANCO GALICIA Y BANCO
                  BBVA. LA PROMOCIÓN CON LAS TARJETAS DE CRÉDITO DE LOS BANCOS GALICIA, SANTANDER Y BBVA ES SOLO VÁLIDA
                  EN LOS CONCESIONARIOS ASOCIADOS DE LA RED. COSTO FINANCIERO TOTAL (CFT): 0,0%, TASA NOMINAL ANUAL:
                  0,0$, TASA EFECTIVA ANUAL: 0,0%, CFT: 0,0%. NO ACUMULABLE CON OTRAS PROMOCIONES NI DESCUENTOS. NO
                  INCLUYE REPARACIONES NI TRABAJOS ADICIONALES, AJENOS AL MENCIONADO PLAN DE MANTENIMIENTO. PARA MÁS
                  INFORMACIÓN Y CONDICIONES O LIMITACIONES APLICABLES, COMUNÍQUESE GRATUITAMENTE AL TELÉFONO
                  0800-888-8696. Toyota Argentina S.A., Planta Industrial Zárate: Ruta 12, Km 81 (B28000FA) Zárate - Bs.
                  As. – Argentina, CUIT: 33-67913936-9
                </p>
                <p className={css['paragraph-container__text']}>CFT: 0,0%</p>
                <p className={css['paragraph-container__text']}>
                  (3) LAS REPARACIONES O RECAMBIOS IDENTIFICADOS COMO NECESARIOS POR USO SEVERO EN LA INSPECCIÓN DEL
                  VEHÍCULO NO ESTAN INCLUIDOS EN EL PRECIO PUBLICADO Y PODRÁN MODIFICAR EL PLAN DE MANTENIMIENTO
                  PERIODICO ESTIPULADO.
                </p>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default MaintenancePlan;
