import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class Zuora implements ContentBlock {
  label = 'Zuora';

  component = 'Zuora';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'pageId',
      label: 'Page ID',
      help: 'ID of the Zuora Page',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'url',
      label: 'URL',
      help: 'Zuora URL',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'supportedTypes',
      label: 'Supported Types',
      help: 'Supported Payment Types',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'gateway',
      label: 'Payment Gateway',
      help: 'Payment Gateway',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'parentCallback',
      label: 'Callback Name',
      help: 'Callback to pass form response',
      type: 'String',
      control: 'input:text',
      defaultValue: 'debugCallback'
    }
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const locale = extra.context.locale.replace('-', '_');

    return {
      anchor: extra?.settings?.anchor,
      pageId: extra?.settings?.pageId,
      locale: locale,
      supportedTypes: extra?.settings?.supportedTypes,
      url: extra?.settings?.url,
      gateway: extra?.settings?.gateway,
      parentCallback: extra?.settings?.parentCallback
    };
  }
}

export { Zuora };
