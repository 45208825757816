import React from 'react';
import i18n from 'utils/i18n';
import ContentDivider from 'components/atoms/content-divider';
import RichTextSection from 'components/organisms/rich-text-section';
import SearchResultCard from 'components/molecules/search-result-card';
import Title from 'components/atoms/title';

// loading the sass style fot the component
import css from './styles.module.scss';
import SectionCard from '../section-card';

/**
 * Organism SearchResultList
 *
 */
function SearchResultList(props) {
  const t = i18n.useTranslations('components.search-result-list');

  const {
    className = '',
    children,
    data = [],
    spacingTop = 0,
    spacingBottom = 0,
    bgColor = 'default',
    titleNoResults,
    textNoResults,
    token = '',
    type = 'default',
    totalItems,
    removeResultData = false,
    ...other
  } = props;

  const numberItems = type === 'news' ? totalItems : data.length;
  const settings = {
    isUppercase: false,
    removeCarousel: true
  };

  const listItemError = [
    t('content-no-result-one'),
    t('content-no-result-second'),
    t('content-no-result-third')];

  return (
    <div className={`${css['organism__search-result-list-container']} ${className}`} {...other}>
      {!removeResultData && !!data.length && (
        <p className={css['intro-content']}>
          {t('content-display-one')} {numberItems} {t('content-display-second')}&nbsp;
          <span className={css['intro-content__highlight']}>{token}</span>
        </p>
      )}
      {!data.length && (
        <div className={css['error-list-container']}>
          <Title variant={5}> {titleNoResults || t('title-no-result')} </Title>
          <div className={css['error-list']}>
            {textNoResults ? (
              <RichTextSection
                spacingTop={spacingTop}
                spacingBottom={spacingBottom}
                bgColor={bgColor}
                textContent={textNoResults}
              />
            ) : (
              listItemError.map((item, i) => {
                return (
                  <div className={css['error-list-item']} key={i}>
                    <div className={css['error-list-item-marker']}>•</div>
                    <span className={css['error-list-item-span']}>{item}</span>
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
      {!removeResultData && data?.length > 1 && <ContentDivider className={css['divider']} />}
      {type === 'default' && !!data.length && (
        <div className={css['data-list-container']}>
          <ul className={css['data-list']}>
            {data.map((item, i) => {
              return (
                <li key={i}>
                  <SearchResultCard
                    className={css['data-list-card']}
                    title={item?.title}
                    link={item?.fullpath}
                    target="_blank"
                  >
                    {item?.description}
                  </SearchResultCard>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {type === 'news' && !!data.length && (
        <div className={css['data-list-container']}>
          <SectionCard cardsData={data} settings={settings} />
        </div>
      )}
    </div>
  );
}

export default SearchResultList;
