import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor, spacingTopMeta, spacingBottomMeta } from 'utils/meta';

class GalleryDownload implements ContentBlock {
  label = 'Gallery Download';

  component = 'GalleryDownload';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = true;

  settings = [
    {
      name: 'title',
      label: 'Gallery title',
      help: 'Title to be displayed in the gallery component',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    anchor,
    spacingTopMeta,
    spacingBottomMeta

  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'Set the image title',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'media',
      label: 'Lower resolution',
      help: 'Set a low-resolution image for the gallery',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      required: true,
      props: null
    },
    {
      name: 'mediaMob',
      label: 'Higher resolution',
      help: 'Set a high-resolution image for the gallery',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    }
  ];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    contents.forEach((content) => {
      if (!content.media) {
        throw Error('A propriedade "media" é obrigatória.');
      }
    });

    return {
      title: extra?.settings.title,
      galleryData: contents.map((content) => {
        return {
          url: content?.media?.url,
          urlHighResolution: content?.mediaMob?.url
        };
      })
    };
  }
}

export { GalleryDownload };
