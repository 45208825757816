/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import GalleryModal from 'components/molecules/gallery-modal';
import i18n from 'utils/i18n';

// loading the sass style for the component
import css from './styles.module.scss';

/**
 * Organism Gallery
 *
 * <!-- TODO: add a description here! -->
 */
function Gallery(props) {
  const t = i18n.useTranslations('components.gallery');

  const {
    className = '',
    galleryData = [],
    coverImage,
    title,
    size = 'default',
    opacity = 'true',
    hasVehicleTitle = true,
    isDownload,
    onButtonClick,
    index,
    backgroundModal,
    ...other
  } = props;

  const [clickedImage, setClickedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (galleryData.length > 0) {
      try {
        setClickedImage(galleryData[0].url);
      } catch (error) {
        console.log('Error: ', error);
      }
    }
  }, [galleryData]);

  const handleClick = () => {
    setOpenModal(true);
    if (onButtonClick && index !== undefined) {
      onButtonClick(index);
    }
  };

  const handleNextImage = () => {
    const totalLength = galleryData.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = galleryData[0].url;
      setClickedImage(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = galleryData[newIndex].url;
    setClickedImage(newUrl);
    setCurrentIndex(newIndex);
  };

  return (
    <div className={`${css['organism__gallery-container']} ${className}`} data-size={size} {...other}>
      <button onClick={handleClick} className={css['gallery-button']}>
        <span className={css['title-sup']}>{hasVehicleTitle && t('title')}</span>
        <span className={css['title-inf']}>{title}</span>
        <img src={coverImage} alt="interior" className={css['gallery-button__image']} opacity={opacity} />
      </button>

      {openModal && (
        <GalleryModal
          dataGallery={galleryData}
          clickedImage={clickedImage}
          handleNextImage={handleNextImage}
          setClickedImage={setClickedImage}
          setOpenModal={setOpenModal}
          isDownload={isDownload}
          initialIndex={index}
          backgroundModal={backgroundModal}
        />
      )}
    </div>
  );
}

export default Gallery;
