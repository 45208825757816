/* eslint-disable no-unused-vars */
import { captchaToken } from 'utils/captcha/token';
import { chassiMask, cpfMask, isValidBrazilPhoneNumber, isValidCPF } from 'utils/validations';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import FormCheckbox from 'components/atoms/form-checkbox';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import FormTextarea from 'components/atoms/form-text-area';
import getConfig from 'next/config';
import i18n from 'utils/i18n';
import { phoneMask } from 'utils/validations';
import React, { useCallback, useEffect, useState } from 'react';
import Script from 'next/script';
import Section from 'components/utils/section';
import SectionHeader from 'components/molecules/section-header';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import Wrapper from 'components/atoms/wrapper';
import { isNumberMask, isTextMask, removeSpecialCharacters } from 'utils/forms';

// loading the sass style fot the component
import css from './styles.module.scss';
import { empty } from 'utils/functions';
import ContentDivider from 'components/atoms/content-divider';

const { publicRuntimeConfig } = getConfig();

function SimplifiedContactForm(props) {
  const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.simplified-contact-form');

  const {
    className = '',
    locale,
    headerSpacing,
    bgColor = 'white',
    title,
    description,
    formType,
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    children,
    contactFormType,
    ...other
  } = props;

  const [isFormIncomplete, setIsFormIncomplete] = useState(true);
  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [isSubmitting, setIsSubmitting] = useState(false);
  let validationSchema;

  if (locale === 'pt-br') {
    if (formType === 'DREAM_CAR' || formType === 'TOYOTA_TOUR_CONTACT') {
      validationSchema = yup
        .object()
        .shape({
          firstName: yup
            .string()
            .trim()
            .required(t('message_error_required_firstName'))
            .min(3, t('message_error_min_char_firstName')),
          lastName: yup
            .string()
            .trim()
            .required(t('message_error_required_lastName'))
            .min(3, t('message_error_min_char_lastName')),
          customerId: yup
            .string()
            .required(t('message_error_required_customerId'))
            .test('test-invalid-cpf', t('message_error_invalid_customerId'), (customerId) => isValidCPF(customerId))
            .min(14, t('message_error_invalid_customerId')),
          email: yup
            .string()
            .trim()
            .required(t('message_error_required_email'))
            .email(t('message_error_invalid_email'))
            .max(50, t('message_error_max_char_email')),
          requestType: yup.string().required(t('message_error_required_requestType')),
          message: yup
            .string()
            .trim()
            .required(t('message_error_required_message'))
            .min(3, t('message_error_min_char_message'))
            .max(255, t('message_error_max_char_message')),
          agreement: yup.boolean().oneOf([true], t('message_error_required_agreement'))
        })
        .required();
    }
    if (formType === 'SAC') {
      validationSchema = yup
        .object()
        .shape({
          firstName: yup
            .string()
            .trim()
            .required(t('message_error_required_firstName'))
            .min(3, t('message_error_min_char_firstName')),
          lastName: yup
            .string()
            .trim()
            .required(t('message_error_required_lastName'))
            .min(3, t('message_error_min_char_lastName')),
          customerId: yup
            .string()
            .required(t('message_error_required_customerId'))
            .test('test-invalid-cpf', t('message_error_invalid_customerId'), (customerId) => isValidCPF(customerId)),
          email: yup
            .string()
            .trim()
            .required(t('message_error_required_email'))
            .email(t('message_error_invalid_email'))
            .max(50, t('message_error_max_char_email')),
          telephone: yup.string().trim().required(t('message_error_required_phone')),
          requestType: yup.string().required(t('message_error_required_requestType')),
          message: yup
            .string()
            .trim()
            .required(t('message_error_required_message'))
            .min(3, t('message_error_min_char_message')),
          agreement: yup.boolean().oneOf([true], t('message_error_required_agreement'))
        })
        .required();
    }
  } else {
    if (formType === 'RECALL') {
      validationSchema = yup
        .object()
        .shape({
          firstName: yup
            .string()
            .trim()
            .required(t('message_error_required_firstName'))
            .min(1, t('message_error_min_char_firstName'))
            .max(40, t('message_error_max_char_firstName')),
          lastName: yup
            .string()
            .trim()
            .required(t('message_error_required_lastName'))
            .min(1, t('message_error_min_char_lastName'))
            .max(80, t('message_error_max_char_lastName')),
          patent: yup.string().required(t('message_error_required_patent')),
          phone: yup
            .string()
            .trim()
            .required(t('message_error_required_phone'))
            .min(8, t('message_error_min_char_phone'))
            .max(12, t('message_error_max_char_phone')),
          email: yup
            .string()
            .trim()
            .required(t('message_error_required_email'))
            .email(t('message_error_invalid_email'))
            .max(80, t('message_error_max_char_email')),
          frame: yup.string().test('is-17-digits', t('message_error_invalid_frame_length'), (value) => {
            if (!value) {
              return true;
            }
            return value.length === 17;
          })
        })
        .required();
    }
    if (formType === 'QUEJAS') {
      validationSchema = yup
        .object()
        .shape({
          firstName: yup
            .string()
            .trim()
            .required(t('message_error_required_firstName'))
            .min(1, t('message_error_min_char_firstName'))
            .max(40, t('message_error_max_char_firstName')),
          lastName: yup
            .string()
            .trim()
            .required(t('message_error_required_lastName'))
            .min(1, t('message_error_min_char_lastName'))
            .max(80, t('message_error_max_char_lastName')),
          document: yup
            .string()
            .required(t('message_error_required'))
            .min(6, t('message_error_invalid_document'))
            .max(8, t('message_error_invalid_document')),
          email: yup
            .string()
            .trim()
            .required(t('message_error_required'))
            .email(t('message_error_invalid_email'))
            .max(50, t('message_error_invalid_email')),
          phone: yup
            .string()
            .required(t('message_error_required_phone'))
            .test('5-numeros-iguales', t('message_error_invalid_phone_QUEJAS'), (value) =>
              isValidBrazilPhoneNumber(value)
            )
            .min(8, t('message_error_min_char_phone')),
          commentary: yup
            .string()
            .trim()
            .required(t('message_error_required'))
            .min(5, t('message_error_min_char_commentary'))
        })
        .required();
    }
  }

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const watchedFieldsTDB = watch([
    'firstName',
    'lastName',
    'customerId',
    'email',
    'requestType',
    'message',
    'agreement'
  ]);

  const watchedFieldsTDBCONTACT = watch([
    'firstName',
    'lastName',
    'customerId',
    'email',
    'telephone',
    'requestType',
    'message'
  ]);

  const watchedFieldsTASARECALL = watch([
    'firstName',
    'lastName',
    'email',
    'patent',
    'phone'
  ]);

  const watchedFieldsTASAQUEJAS = watch([
    'firstName',
    'lastName',
    'document',
    'email',
    'phone',
    'commentary'
  ]);

  const watchedAgreement = watch('agreement');

  useEffect(() => {
    validateForm();
  }, [
    watchedFieldsTDB,
    watchedFieldsTASARECALL,
    watchedFieldsTASAQUEJAS,
    watchedFieldsTDBCONTACT
  ]);

  function validateForm() {
    let isFormEmpty;

    if (locale === 'pt-br') {
      if (formType === 'DREAM_CAR' || formType === 'TOYOTA_TOUR_CONTACT') {
        isFormEmpty = watchedFieldsTDB.some((value) => empty(value) || value === 'placeholder');
        isFormEmpty || watchedAgreement === false ? setIsFormIncomplete(true) : setIsFormIncomplete(false);
      }
      if (formType === 'SAC') {
        isFormEmpty = watchedFieldsTDBCONTACT.some((value) => empty(value) || value === 'placeholder');
        isFormEmpty || watchedAgreement === false ? setIsFormIncomplete(true) : setIsFormIncomplete(false);
      }
    }
    if (formType === 'RECALL') {
      isFormEmpty = watchedFieldsTASARECALL.some((value) => empty(value) || value === 'placeholder');
      isFormEmpty ? setIsFormIncomplete(true) : setIsFormIncomplete(false);
    }
    if (formType === 'QUEJAS') {
      isFormEmpty = watchedFieldsTASAQUEJAS.some((value) => empty(value) || value === 'placeholder');
      isFormEmpty ? setIsFormIncomplete(true) : setIsFormIncomplete(false);
    }
  }

  const resetForm = useCallback(() => {
    let result;
    if (formType === 'DREAM_CAR' || formType === 'TOYOTA_TOUR_CONTACT') {
      result = {
        firstName: '',
        lastName: '',
        customerId: '',
        email: '',
        requestType: 'placeholder',
        message: '',
        agreement: false
      };
    }
    if (formType === 'SAC') {
      result = {
        firstName: '',
        lastName: '',
        customerId: '',
        email: '',
        telephone: '',
        chassi: '',
        requestType: 'placeholder',
        message: '',
        agreement: false
      };
    }
    if (formType === 'RECALL') {
      result = { firstName: '', lastName: '', patent: '', phone: '', email: '', frame: '' };
    }
    if (formType === 'QUEJAS') {
      result = { firstName: '', lastName: '', document: '', email: '', phone: '', commentary: '' };
    }
    reset(result);
  }, [reset]);

  const handleKeyDownName = (event) => {
    const key = event.key;
    const isSpecialCharacterOrNumber = /^[^a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ\s]+$/.test(key);

    if (isSpecialCharacterOrNumber) {
      event.preventDefault();
    }
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    data.locale = locale;
    data.type = formType.toUpperCase();

    const captchaPayload = await captchaToken('CONTACT', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');

    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsSubmitting(false);

      return false;
    }

    if (formType === 'SAC') {
      data.name = data.firstName;
      data.surname = data.lastName;
      data.cpf = data.customerId.replace(/\D/g, '');
      data.telephone = data.telephone.replace(/\D/g, '');
      data.solicitationType = data.requestType;
      data.chassi = data.chassi.replace(/\s/g, '');
      data.subject = `${data.requestType}`;
      delete data.requestType;
    }

    if (formType === 'RECALL' || formType === 'QUEJAS') {
      data.name = data.firstName;
      data.surname = data.lastName;
      data.dealershipEmail = data.email;
    }
    data.captchaPayload = captchaPayload;

    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    const url =
      locale === 'pt-br' && formType === 'SAC' && publicRuntimeConfig?.FEATURE_SAC_SALESFORCE
        ? '/api/salesforce/contactCustomerService'
        : '/api/contact';

    fetch(url, opts)
      .then((res) => {
        if (res.status === 200) {
          setIsSubmitting(false);
          resetForm(reset);
          setIsStatusComponent('success');

          return;
        }
        setIsSubmitting(false);
        setIsStatusComponent('error');
      })
      .catch((err) => {
        setIsSubmitting(false);
        console.log(err);
      });
  };

  function onError(error) {
    console.log('Error: ', error);
  }

  function onBackForm() {
    setIsStatusComponent('default');
  }

  return (
    <div className={`${css['molecule__simplified-contact-form-container']} ${className}`} {...other}>
      <Wrapper spacing={headerSpacing} bgColor={bgColor}>
        <Section className={css['success-container']}>
          <Script
            src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
          ></Script>

          {isStatusComponent === 'success' && (
            <SuccessSubmitForm
              onBackForm={onBackForm}
              titleSuccess={titleSuccess ? titleSuccess : t('success_title')}
              contentSuccess={contentSuccess ? contentSuccess : t('success_content')}
              contentBackForm={t('content_back_form')}
              contentBackHome={t('content_back_home')}
            />
          )}

          {isStatusComponent === 'error' && (
            <SuccessSubmitForm
              onBackForm={onBackForm}
              titleSuccess={titleError ? titleError : t('error_title')}
              contentSuccess={contentError ? contentError : t('error_content')}
              contentBackForm={t('content_back_form')}
              contentBackHome={t('content_back_home')}
            />
          )}
          {isStatusComponent === 'default' && (
            <>
              <SectionHeader
                className={formType === 'SAC' ? css['header_SAC'] : css['header']}
                title={title ? title : t('title_form')}
                description={{
                  value: formType === 'SAC' ? null : description ? description : t('description_form'),
                  textColor: 'gray-4'
                }}
                image={
                  formType !== 'RECALL' &&
                  formType !== 'QUEJAS' && {
                    src: '/images/contact-form-header.png',
                    alt: ''
                  }
                }
              />
              {formType === 'SAC' && (
                <div className={css['header-content']}>
                  <p>{t('subtitle_CONTACT')}</p>
                  <p className={css['subtitle-contact-form']}>{t('description_form')}</p>
                </div>
              )}
              {(formType === 'RECALL' || formType === 'QUEJAS') && <p className={css['subtitle']}>{t('subtitle')}</p>}

              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                data-grid
                data-grid-list={formType === 'SAC' ? '2' : '3'}
              >
                {locale === 'pt-br' && (
                  <>
                    {' '}
                    <FormInput
                      className={css['form-field']}
                      id="firstName"
                      label={t('label_user_firstName_field')}
                      placeholder={t('placeholder_user_firstName_field')}
                      dataRegister={register('firstName')}
                      onKeyDown={handleKeyDownName}
                      maxLength="50"
                      dataErrors={errors['firstName']}
                      type="text"
                    />
                    <FormInput
                      className={css['form-field']}
                      id="lastName"
                      label={t('label_user_lastName_field')}
                      placeholder={t('placeholder_user_lastName_field')}
                      dataRegister={register('lastName')}
                      onKeyDown={handleKeyDownName}
                      dataErrors={errors['lastName']}
                      maxLength="50"
                      type="text"
                    />
                    {formType === 'SAC' ? (
                      <>
                        <FormInput
                          className={css['form-field']}
                          id="email"
                          label={t('label_user_email_field')}
                          dataRegister={register('email')}
                          dataErrors={errors['email']}
                          type="text"
                          placeholder={t('label_user_email_field')}
                        />
                        <FormInput
                          className={css['form-field']}
                          id="customerId"
                          label={t('label_user_customerId_field')}
                          placeholder={t('placeholder_user_customerId_field')}
                          type="text"
                          dataRegister={register('customerId')}
                          dataErrors={errors['customerId']}
                          maxLength={formType === 'SAC' ? null : '14'}
                          masking={(event) => {
                            const value = event.target.value;
                            event.target.value = cpfMask(value);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <FormInput
                          className={css['form-field']}
                          id="customerId"
                          label={t('label_user_customerId_field')}
                          placeholder={t('placeholder_user_customerId_field')}
                          type="text"
                          dataRegister={register('customerId')}
                          dataErrors={errors['customerId']}
                          maxLength={formType === 'SAC' ? null : '14'}
                          masking={(event) => {
                            const value = event.target.value;
                            event.target.value = cpfMask(value);
                          }}
                        />
                        <FormInput
                          className={css['form-field']}
                          id="email"
                          label={t('label_user_email_field')}
                          dataRegister={register('email')}
                          dataErrors={errors['email']}
                          type="text"
                          placeholder={t('label_user_email_field')}
                        />
                      </>
                    )}
                    {formType === 'SAC' && (
                      <>
                        <FormInput
                          className={css['form-field']}
                          id="telephone"
                          label={t('label_user_phone_field')}
                          placeholder={t('placeholder_user_phone_field')}
                          dataRegister={register('telephone')}
                          dataErrors={errors['telephone']}
                          maxLength="15"
                          masking={(event) => {
                            const value = event.target.value;
                            event.target.value = phoneMask(value);
                          }}
                        />
                        <FormInput
                          className={css['form-field']}
                          id="chassi"
                          label={t('label_frame_field')}
                          placeholder={t('placeholder_frame_field')}
                          type="text"
                          maxLength="21"
                          dataRegister={register('chassi')}
                          dataErrors={errors['chassi']}
                          masking={(event) => {
                            const value = event.target.value;
                            event.target.value = chassiMask(value);
                            event.target.value = removeSpecialCharacters(value);
                          }}
                        />
                      </>
                    )}
                    <FormSelect
                      className={css['form-field']}
                      id="requestType"
                      placeholder={t('placeholder_user_requestType_field')}
                      label={t('label_user_requestType_field')}
                      dataRegister={register('requestType')}
                      dataErrors={errors['requestType']}
                    >
                      <option value={t('label_praise')}>{t('label_praise')}</option>
                      <option value={t('label_information')}>{t('label_information')}</option>
                      <option value={t('label_suggestion')}>{t('label_suggestion')}</option>
                      <option value={t('label_complaint')}>{t('label_complaint')}</option>
                    </FormSelect>
                    <div data-grid-full-width>
                      <FormTextarea
                        className={css['form-field']}
                        id="message"
                        type="text"
                        placeholder={t('placeholder_user_message_field')}
                        label={t('label_user_message_field')}
                        maxLength={formType === 'SAC' ? '250' : '255'}
                        dataRegister={register('message')}
                        dataErrors={errors['message']}
                      />
                    </div>
                    {formType === 'SAC' && (
                      <div data-grid-full-width className={css['divider']}>
                        <ContentDivider />
                      </div>
                    )}
                    <div data-grid-full-width className={formType === 'SAC' ? css['agreement_SAC'] : null}>
                      <FormCheckbox
                        id="agreement"
                        dataRegister={register('agreement')}
                        dataErrors={errors['agreement']}
                        className={css['agreement']}
                      >
                        {t('label_agreement')}&nbsp;
                        <AnchorLink
                          link="/politica-de-privacidade"
                          hasArrow={false}
                          linkLabel={t('link_label_agreement')}
                        />
                      </FormCheckbox>
                    </div>
                  </>
                )}

                {formType === 'RECALL' && (
                  <>
                    <FormInput
                      className={css['form-field']}
                      id="firstName"
                      label={t('label_user_firstName_field')}
                      placeholder={t('placeholder_user_firstName_field')}
                      dataRegister={register('firstName')}
                      onKeyDown={handleKeyDownName}
                      maxLength="40"
                      dataErrors={errors['firstName']}
                      type="text"
                    />
                    <FormInput
                      className={css['form-field']}
                      id="lastName"
                      label={t('label_user_lastName_field')}
                      placeholder={t('placeholder_user_lastName_field')}
                      dataRegister={register('lastName')}
                      onKeyDown={handleKeyDownName}
                      dataErrors={errors['lastName']}
                      maxLength="80"
                      type="text"
                    />
                    <FormInput
                      className={css['form-field']}
                      id="patent"
                      label={t('label_patent_field')}
                      placeholder={t('placeholder_patent_field')}
                      dataRegister={register('patent')}
                      dataErrors={errors['patent']}
                      masking={(event) => {
                        const value = event.target.value;
                        event.target.value = removeSpecialCharacters(value).trim();
                      }}
                    />
                    <FormInput
                      className={css['form-field']}
                      id="phone"
                      label={t('label_user_phone_field')}
                      placeholder={t('placeholder_user_phone_field')}
                      dataRegister={register('phone')}
                      maxLength="12"
                      dataErrors={errors['phone']}
                      masking={(event) => {
                        const value = event.target.value;
                        event.target.value = isNumberMask(value);
                      }}
                    />
                    <FormInput
                      className={css['form-field']}
                      id="email"
                      label={t('label_user_email_field')}
                      dataRegister={register('email')}
                      dataErrors={errors['email']}
                      maxLength="80"
                      type="text"
                      placeholder={t('label_user_email_field')}
                    />
                    <FormInput
                      className={css['form-field']}
                      id="frame"
                      label={t('label_frame_field')}
                      placeholder={t('placeholder_frame_field')}
                      dataRegister={register('frame')}
                      dataErrors={errors['frame']}
                      maxLength="17"
                      masking={(event) => {
                        const value = event.target.value;
                        event.target.value = removeSpecialCharacters(value).trim();
                      }}
                    />
                    <div data-grid-full-width className={css['agreement-tasa']}>
                      {t('label_agreement')}&nbsp;
                      <AnchorLink
                        link="/politica-de-privacidad"
                        hasArrow={false}
                        linkLabel={t('link_label_agreement')}
                      />
                      {t('continuation_label_agreement')}
                    </div>
                  </>
                )}

                {formType === 'QUEJAS' && (
                  <>
                    <FormInput
                      className={css['form-field']}
                      id="firstName"
                      label={t('label_user_firstName_field')}
                      placeholder={t('placeholder_user_firstName_field')}
                      dataRegister={register('firstName')}
                      onKeyDown={handleKeyDownName}
                      maxLength="40"
                      dataErrors={errors['firstName']}
                      type="text"
                    />
                    <FormInput
                      className={css['form-field']}
                      id="lastName"
                      label={t('label_user_lastName_field')}
                      placeholder={t('placeholder_user_lastName_field')}
                      dataRegister={register('lastName')}
                      onKeyDown={handleKeyDownName}
                      dataErrors={errors['lastName']}
                      maxLength="80"
                      type="text"
                    />
                    <FormInput
                      className={css['form-field']}
                      id="document"
                      label={t('label_user_document_field')}
                      placeholder={t('placeholder_user_document_field_QUEJAS')}
                      type="text"
                      dataRegister={register('document')}
                      dataErrors={errors['document']}
                      maxLength="8"
                      masking={(event) => {
                        const value = event.target.value;
                        event.target.value = isNumberMask(value);
                      }}
                    />
                    <FormInput
                      className={css['form-field']}
                      id="email"
                      label={t('label_user_email_field')}
                      dataRegister={register('email')}
                      dataErrors={errors['email']}
                      type="text"
                      maxLength="50"
                      placeholder={t('placeholder_user_email_field_QUEJAS')}
                    />
                    <FormInput
                      className={css['form-field']}
                      id="phone"
                      label={t('label_user_phone_field')}
                      placeholder={t('placeholder_user_phone_field_QUEJAS')}
                      dataRegister={register('phone')}
                      maxLength="12"
                      dataErrors={errors['phone']}
                      masking={(event) => {
                        const value = event.target.value;
                        event.target.value = isNumberMask(value);
                      }}
                    />
                    <div data-grid-full-width>
                      <FormTextarea
                        className={css['form-field']}
                        id="commentary"
                        type="text"
                        placeholder={t('placeholder_user_commentary_field')}
                        label={t('label_user_commentary_field')}
                        dataRegister={register('commentary')}
                        dataErrors={errors['commentary']}
                      />
                    </div>
                    <div data-grid-full-width className={css['agreement-tasa']}>
                      {t('label_agreement_QUEJAS')}
                      <br />
                      {t('continuation_label_agreement_QUEJAS')}
                      <AnchorLink
                        link="/terminos-y-condiciones"
                        hasArrow={false}
                        linkLabel={t('link_label_agreement_QUEJAS')}
                      />
                      {t('continuation_label_agreement_politica_QUEJAS')}
                      <AnchorLink
                        link="/politica-de-privacidad"
                        hasArrow={false}
                        linkLabel={t('link_label_agreement_politica_QUEJAS')}
                      />
                      {t('continuation_label_agreement_politica_final_QUEJAS')}
                    </div>
                  </>
                )}

                <div data-grid-full-width className={css['form-submit-container']}>
                  <Button
                    type="submit"
                    className={css['submit_button']}
                    disabled={Object.keys(errors).length > 0 || isFormIncomplete || isSubmitting}
                  >
                    {t('button_submit_form')}
                  </Button>
                </div>
              </form>
            </>
          )}
        </Section>
      </Wrapper>
    </div>
  );
}

export default SimplifiedContactForm;
