import React from 'react';
import i18n from 'utils/i18n';
import { IconSpinner } from 'components/icons';

// loading the sass style fot the component
import css from './styles.module.scss';

// Define the component's props
export interface LoaderOverlayProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * Additional text to be displayed on the loading overlay.
   */
  content?: string;
}

/**
 * LoaderOverlay Component
 *
 * A component that displays an overlay on the screen to indicate that data is being loaded, blocking user interaction until the operation is complete.
 *
 * @param {LoaderOverlayProps} props - The props for the LoaderOverlay component.
 * @returns {JSX.Element} A JSX element representing the loading overlay.
 */
const LoaderOverlay: React.FC<LoaderOverlayProps> = ({ className = '', content = null, ...other }) => {
  const t = i18n.useTranslations('components.loader-overlay');
  const loaderText = t('loader_text') || null;

  return (
    <div
      className={`${css['molecule__loader-overlay-container']} ${className}`}
      role="status"
      aria-live="polite"
      {...other}
    >
      <div className={css['loader-container']}>
        <div className={css['loader-spinner']}>
          <IconSpinner className={css['icon-spinner']} />
        </div>
        <div className={css['loader-content']} data-testid="loader-content">
          {loaderText && <span data-testid="loader-text">{loaderText}</span>}
          {content && <span data-testid="additional-content">{content}</span>}
        </div>
      </div>
    </div>
  );
};

export default LoaderOverlay;
