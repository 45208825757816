import React from 'react';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import ServiceDropdownCard from 'components/molecules/service-dropdown-card';
import Button from 'components/atoms/button';
import { useMediaQuery } from 'react-responsive';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism ServiceSingleGrid
 *
 * Grid to display a single service with sidebar
 */
function ServiceSingleGrid(props) {
  const t = i18n.useTranslations('components.service-single-grid');

  const {
    className = '',
    bgColor = 'gray',
    spacingTop = 4,
    spacingBottom = null,
    services = [],
    service,
    locale,
    children
  } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const allowedLocales = ['PT-BR', 'ES-VE'];

  function shouldShowButton(locale, allowedLocales) {
    const normalizedLocale = locale?.toUpperCase();
    return allowedLocales?.includes(normalizedLocale);
  }

  return (
    <Wrapper
      className={`${css['organism__service-single-grid-container']} ${className}`}
      bgColor={bgColor}
      data-spacing-top={spacingTop}
      data-spacing-bottom={spacingBottom}
    >
      <Section hasGrid>
        <div className={css['services-header']}>
          {!isMobile ? <ServiceDropdownCard data-hide="mobile" services={services} current={service} /> : null}
          {shouldShowButton(locale, allowedLocales) && (
            <Button size="large" link={t('link')}>
              {t('button-label')}
              <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L10 9L1 17" stroke="white" />
              </svg>
            </Button>
          )}
        </div>
        <div className={css['services-content']}>{children}</div>
      </Section>
    </Wrapper>
  );
}

export default ServiceSingleGrid;
