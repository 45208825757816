/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import Script from 'next/script';

declare let Z: any;

/**
 * Molecule Zuora
 *
 * This component do Page integration with the Zuora Payments
 * Solution: https://knowledgecenter.zuora.com/Zuora_Payments/Payment_Page_and_Payment_Link/Payment_Pages_2.0/EA_Integrate_Payment_Pages_2.0#Include_the_Zuora_JavaScript_Library
 */
function Zuora(props: {
  anchor: string;
  tenantId: string;
  pageId: string;
  token: string;
  signature: string;
  zKey: string;
  locale: string;
  supportedTypes: string;
  url: string;
  gateway: string;
  parentCallback: string;
  children: any;
}) {
  const { anchor, pageId, locale, supportedTypes, url, gateway, parentCallback, children } = props;

  // params for rendering iframe on the client side
  const params = {
    tenantId: '',
    id: pageId,
    token: '',
    signature: '',
    style: 'overlay',
    key: '',
    submitEnabled: 'true',
    locale: locale,
    param_supportedTypes: supportedTypes,
    url: url,
    paymentGateway: gateway
  };

  // An default debug callback to validation. We can use it at AMOS to see the actual
  // form response
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const debugCallback = useCallback((response: any) => {
    alert('Debug Callback: look at the console');
    console.debug('Debug callback called with Response', response);
  }, []);

  const proxyCallback = useCallback(
    function (response: any) {
      if (typeof parentCallback === 'undefined' || parentCallback === '' || parentCallback === 'debugCallback') {
        console.debug(`Callable was not specified. Using the fallback.`);

        return debugCallback(response);
      }

      if (typeof eval(parentCallback) !== 'function') {
        console.error(`Cannot found any callable with the name '${parentCallback}'`);
        return;
      }

      let context = window;
      const namespaces = parentCallback.split('.');
      const func = namespaces.pop();

      for (let i = 0; i < namespaces.length; i++) {
        context = context[namespaces[i]];
      }

      return context[func].apply(response);
    },
    [parentCallback]
  );

  const getSignature = useCallback(async () => {
    return fetch(`/api/zuora/sign-request?pageId=${pageId}&uri=${url}`)
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        console.debug('Failed to get Zuora signature', err);
      });
  }, [url, pageId]);

  const initZuora = useCallback(async () => {
    const signatureData = await getSignature();

    params.tenantId = signatureData.tenant;
    params.key = signatureData.key;
    params.signature = signatureData.signature;
    params.token = signatureData.token;

    Z.setEventHandler('onloadCallback', function () {
      console.info('HPM page is loaded.');
    });

    Z.render(params, {}, proxyCallback);
  }, [proxyCallback]);

  return (
    <div id={anchor}>
      <Script
        src="https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js"
        strategy="afterInteractive"
        onLoad={initZuora}
      ></Script>
      <div id="zuora_payment"></div>
      {children}
    </div>
  );
}

export default Zuora;
