import React from 'react';
import { usePaginationRange, DOTS } from 'utils/pagination/index';
import { IconArrowLeft, IconArrowRight } from 'components/icons';

import css from './styles.module.scss';

const Pagination = (props) => {
  const { totalPages, className = '', onPageChange, currentPage, ...other } = props;

  const paginationRange = usePaginationRange({ totalPages, currentPage });


  function scrollToTop() {
    const element = document.getElementById('main');
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 1000);
    }
  }


  function goToNextPage() {
    onPageChange(currentPage + 1);
    scrollToTop();
  }

  function gotToPreviousPage() {
    onPageChange(currentPage - 1);
    scrollToTop();
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    onPageChange(pageNumber);
    scrollToTop();
  }

  return (
    <div className={`${css['molecule__pagination-container']} ${className}`} {...other}>
      <ul className={`${css['pagination-container']}`}>
        <li>
          <button
            className={`${css['arrow-container__item']} ${css['left']} ${currentPage === 1 ? css['disabled'] : ''}`}
            onClick={gotToPreviousPage}
            disabled={currentPage === 1}
            aria-label="Go to previous page"
          >
            <IconArrowLeft />
          </button>
        </li>
        {paginationRange &&
          paginationRange?.map((item, index) => {
            if (item === DOTS) {
              return (
                <li key={index} className={`${css['pagination-item']} ${css['dots']}`}>
                  <p>&#8230;</p>
                </li>
              );
            }
            return (
              <li key={index}>
                <button
                  className={`${css['pagination-item']} ${item === currentPage ? css['selected'] : ''}`}
                  onClick={changePage}
                  aria-label={`Go to page ${item}`}
                >
                  {item}
                </button>
              </li>
            );
          })}
        <li>
          <button
            className={`${css['arrow-container__item']} ${currentPage === totalPages ? css['disabled'] : ''}`}
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            aria-label="Go to next page"
          >
            <IconArrowRight />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
