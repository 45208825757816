import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import Modal from 'components/molecules/simple-modal';
import ProgressStepper from 'components/molecules/progress-stepper';
import Title from 'components/atoms/title';
import Notification from 'components/molecules/notification';
import ExpandedContent from 'components/molecules/expanded-content';
import Card from 'components/molecules/card';
import LoaderOverlay from 'components/molecules/loader-overlay';
import ServiceAppointmentAttendeeStep from 'components/molecules/service-appointment-attendee-step';
import ServiceAppointmentDealersStep from 'components/molecules/service-appointment-dealers-step';
import ServiceAppointmentServicesStep from 'components/molecules/service-appointment-services-step';
import ServiceAppointmentVehicleStep from 'components/molecules/service-appointment-vehicle-step';
import ServiceAppointmentDateAndPriceStep from 'components/molecules/service-appointment-date-and-price-step';
import i18n from 'utils/i18n';
import { removeCityAndStateParamsFromURL, updatePageParam } from 'utils/forms';
import { useServiceAppointment, ACTIONS } from 'providers/service-appointment';
import { dateIso2Br, extractTimeFromDateBr } from 'utils/functions';
import { formatPriceAppointment, getVehiclesForCustomer } from 'utils/functions/appointment';
import css from './styles.module.scss';

function MyShifts(props) {
  const t = i18n.useTranslations('components.my-shifts');
  const router = useRouter();

  const { className = '', locale, dealersProduct, userRegisterData, ...other } = props;
  const { state, dispatch } = useServiceAppointment();
  const { currentStep, listSteps } = state;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFinalStepCompleted, setIsFinalStepCompleted] = useState(false);
  const [listAppointments, setListAppointments] = useState([]);
  const isStepsEmpty = listSteps.length === 0;
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isLegalTextOpen, setIsLegalTextOpen] = useState(false);
  const [orderId, setOrderId] = useState();
  const [isThereVehicles, setIsThereVehicles] = useState(false);
  const [loading, setLoading] = useState(true);
  const historyList = [];

  useEffect(() => {
    if (router?.query?.newSchedule) {
      const vehicleStepIndex = 0;
      dispatch({ type: 'INITIALIZE_LIST_STEPS' });
      dispatch({ type: 'SET_CURRENT_STEP', payload: vehicleStepIndex });
      return;
    }
    dispatch({ type: 'RESET_STATE' });
    removeCityAndStateParamsFromURL();
    fetchAllMyVehicles();
  }, []);

  useEffect(() => {
    if (state?.isMakeAppointmentCompleted) {
      dispatch({ type: 'RESET_STATE' });
      fetchAllMyVehicles();
    }
  }, [state.isMakeAppointmentCompleted]);

  useEffect(() => {
    setIsFinalStepCompleted(state.isFinalStepCompleted);
  }, [state.isFinalStepCompleted]);

  const fetchAllMyAppointments = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/appointment/getAllAppointments`);

      if (!response.ok) {
        throw new Error(`Failed to fetch appointments: ${response.status} - ${response.statusText}`);
      }

      const responseData = await response.json();

      if (responseData?.data?.appointments) {
        setListAppointments(responseData.data.appointments);
      } else {
        throw new Error('Invalid response structure');
      }
    } catch (error) {
      console.log(error);
      setListAppointments([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllMyVehicles = async () => {
    setLoading(true);
    try {
      const vehicles = await getVehiclesForCustomer();

      if (Array.isArray(vehicles) && vehicles.length > 0) {
        setIsThereVehicles(true);
        dispatch({ type: ACTIONS.SET_CUSTOMER_VEHICLES, payload: vehicles });
        await fetchAllMyAppointments();
      }
    } catch (error) {
      console.error(error);
      setIsThereVehicles(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchCancelAppointment = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/appointment/cancel?orderId=${orderId}`);

      if (!response.ok) {
        throw new Error('Failed to cancel the appointment');
      }

      handleNotification('success', t('title_success_notification'), t('content_success_notification'), true);
      await fetchAllMyAppointments();
      await fetchAllMyVehicles();
    } catch (error) {
      console.error(error);
      handleNotification('error', t('title_error_notification'), t('content_error_notification'), true);
    } finally {
      setLoading(false);
      setIsCancelModalOpen(false);
    }
  };

  const handleNext = () => {
    if (state.isFinalStepCompleted) {
      return setIsFinalStepCompleted(state.isFinalStepCompleted);
    }
    const nextStepIndex = currentStep + 1;
    const updatedListSteps = state.listSteps.map((step, index) => {
      if (index === nextStepIndex) {
        return { ...step, disabled: false };
      }
      return step;
    });

    dispatch({ type: 'UPDATE_LIST_STEP', payload: updatedListSteps });
    dispatch({ type: 'SET_CURRENT_STEP', payload: nextStepIndex });
  };

  const handlePrevious = () => {
    removeCityAndStateParamsFromURL();
    dispatch({ type: 'SET_CURRENT_STEP', payload: Math.max(currentStep - 1, 0) });
  };

  const initializeSteps = () => {
    dispatch({ type: 'INITIALIZE_STEPS' });
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleCancelAppointment = () => {
    dispatch({ type: 'RESET_STATE' });
    closeModal();
  };

  const onResetState = () => {
    dispatch({ type: 'RESET_STATE' });
  };

  const handleNotification = (type, title, content, isOpen) => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        content: content,
        title: title,
        type: type,
        isOpen: isOpen
      }
    });
  };
  const renderStepContent = (stepName, stepType) => {
    switch (stepType) {
      case 'VEHICLE':
        return <ServiceAppointmentVehicleStep onNextStep={handleNext} />;
      case 'SERVICES':
        return (
          <ServiceAppointmentServicesStep locale={locale} onPreviousStep={handlePrevious} onNextStep={handleNext} />
        );
      case 'DEALERSHIP':
        return (
          <ServiceAppointmentDealersStep
            locale={locale}
            dealers={dealersProduct}
            userRegisterData={userRegisterData}
            onPreviousStep={handlePrevious}
            onNextStep={handleNext}
          />
        );
      case 'PRICE_DATE':
        return (
          <ServiceAppointmentDateAndPriceStep
            locale={locale}
            km={state.km}
            hasRecall={state.hasRecall}
            onNextStep={handleNext}
          />
        );
      case 'ATTENDEE':
        return (
          <ServiceAppointmentAttendeeStep
            locale={locale}
            userRegisterData={userRegisterData}
            onPreviousStep={handlePrevious}
            onNextStep={handleNext}
          />
        );
      default:
        return <div>Step not found</div>;
    }
  };

  return (
    <div className={`${css['molecule__my-shifts-container']} ${className}`} {...other}>
      {isFinalStepCompleted ? (
        <div className={css['my-shifts__container-initialize']}>
          <div className={css['success-completed-container']}>
            <div className={css['success-completed__header']}>
              <Title level={2} variant={4}>
                {t('success_title')}
              </Title>

              <p className={css['success-completed__header__content']}>{t('success_content')}</p>
            </div>
            <div className={css['success-completed__buttons']}>
              <AnchorLink
                className={css['success-completed_anchor-link']}
                linkLabel={t('label_back_to_shifts')}
                size="small"
                onClick={onResetState}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <Title className={css['my-shifts__title']} variant="2">
            {t('title_shift')}
          </Title>
          {state?.notification && state.notification.isOpen && (
            <Notification
              key={2}
              spacing={4}
              fullScreen={false}
              contentNotification={state?.notification?.content}
              titleNotification={state?.notification?.title}
              typeNotification={state?.notification?.type}
              isOpen={state?.notification?.isOpen}
              handleClose={() => handleNotification('', '', '', false)}
              enableAutoClose
            />
          )}
          {isStepsEmpty ? (
            <>
              {loading ? (
                <LoaderOverlay content={t('content_loading')} />
              ) : (
                <>
                  {isThereVehicles ? (
                    <div className={css['schedule-record']}>
                      {listAppointments?.length > 0 ? (
                        <div className={css['schedule']}>
                          {listAppointments.map((item, index) => {
                            return (
                              <div className={css['appointments']} key={index}>
                                <ExpandedContent
                                  className={css['expanded-content']}
                                  label={
                                    <div className={css['list-item']}>
                                      <p className={css['title-appointments']}>{t('requested_job')}</p>
                                      {item.vehicle && (
                                        <div className={css['appointments-body-1']}>
                                          {item.vehicle.vehicleName && (
                                            <div>
                                              <p className={css['title-p']}>{t('vehicle')}</p>
                                              <p>{item.vehicle.vehicleName}</p>
                                            </div>
                                          )}
                                          {item.vehicle.patent && (
                                            <div>
                                              <p className={css['title-p']}>{t('patent')}</p>
                                              <p>{item.vehicle.patent}</p>
                                            </div>
                                          )}
                                          {item.vehicle.chassi && (
                                            <div>
                                              <p className={css['title-p']}>{t('chassis')}</p>
                                              <p>{item.vehicle.chassi}</p>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      <div className={css['appointments-body-2']}>
                                        {item.receptionDate && (
                                          <div>
                                            <p className={css['title-p']}>{t('date')}</p>
                                            <p>{dateIso2Br(item.receptionDate)}</p>
                                          </div>
                                        )}
                                        {item.receptionDate && (
                                          <div>
                                            <p className={css['title-p']}>{t('hour')}</p>
                                            <p>{extractTimeFromDateBr(item.receptionDate)}</p>
                                          </div>
                                        )}
                                        {item.dealer && item.dealer.name && (
                                          <div>
                                            <p className={css['title-p']}>{t('concessionaire')}</p>
                                            <p>{item.dealer.name}</p>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  }
                                >
                                  <div className={css['details']}>
                                    {(item.hasRecall || item.service) && (
                                      <div className={css['jobs']}>
                                        <div className={css['jobs-body-1']}>
                                          <p className={css['title-p']}>{t('jobs')}</p>
                                          {item.hasRecall && (
                                            <Card
                                              key="recall"
                                              variant="simple-card"
                                              title={t('recall')}
                                              isUppercase={false}
                                              content={t('recall_message')}
                                            />
                                          )}
                                          {item.service && (
                                            <Card
                                              key="mantenimiento"
                                              variant="simple-card"
                                              title={item.type ? ` ${item.service} ${item.type}` : item.service}
                                              isUppercase={false}
                                              content={t('maintenance_message')}
                                            />
                                          )}
                                        </div>
                                        {Object?.values(item.price) && (
                                          <div className={css['price-body']}>
                                            <div>
                                              <p className={css['title-p']}>{t('price')}</p>
                                              <p className={css['price']}>
                                                {formatPriceAppointment(item?.price, locale, t('bonus_text'))}
                                              </p>
                                            </div>
                                            <AnchorLink
                                              className={css['legal']}
                                              linkLabel={t('see_legal')}
                                              onClick={() => setIsLegalTextOpen(true)}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}

                                    <div className={css['details-body']}>
                                      {item.deliveryDate && (
                                        <div className={css['details-body-1']}>
                                          <div>
                                            <p className={css['title-p']}>{t('withdrawal')}</p>
                                            <p>{dateIso2Br(item.deliveryDate)}</p>
                                          </div>
                                          <div>
                                            <p className={css['title-p']}>{t('hour_withdrawal')}</p>
                                            <p>{extractTimeFromDateBr(item.deliveryDate)}</p>
                                          </div>
                                        </div>
                                      )}
                                      {item.dealer && (
                                        <div className={css['details-body-2']}>
                                          {item.dealer.address?.street && (
                                            <div>
                                              <p className={css['title-p']}>{t('address')}</p>
                                              <p>{item.dealer.address.street}</p>
                                            </div>
                                          )}
                                          {item.dealer.phone && (
                                            <div>
                                              <p className={css['title-p']}>{t('telephone')}</p>
                                              <p>{item.dealer.phone}</p>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </ExpandedContent>
                                <div className={css['buttons']}>
                                  <Button
                                    color="light"
                                    disabled={loading || isCancelModalOpen}
                                    onClick={() => {
                                      setOrderId(item.orderId);
                                      setIsCancelModalOpen(true);
                                    }}
                                  >
                                    {t('cancel_button')}
                                  </Button>
                                </div>
                              </div>
                            );
                          })}
                          <p>{t('others_shifts')}</p>
                          <div className={css['buttons']}>
                            <Button onClick={initializeSteps}>{t('title_new_shift')}</Button>
                          </div>
                        </div>
                      ) : (
                        <div className={css['my-shifts__container-initialize']}>
                          <div className={css['no-shifts']}>
                            <p className={css['p-no-shifts']}>{t('no_shifts')}</p>
                            <p className={css['p-scheduled-shifts']}>{t('scheduled_shifts')}</p>
                          </div>
                          <p>{t('others_shifts')}</p>
                          <div className={css['buttons']}>
                            <Button onClick={initializeSteps}>{t('title_new_shift')}</Button>
                          </div>
                        </div>
                      )}
                      {historyList?.length > 0 ? (
                        <div className={css['schedule']}>
                          <Title className={css['my-shifts__title']} variant="2">
                            {t('record_title')}
                          </Title>
                          {historyList.map((item, index) => {
                            return (
                              <div className={css['appointments']} key={index}>
                                <ExpandedContent
                                  label={
                                    <div className={css['list-item']}>
                                      <p className={css['title-appointments']}>Lorem Ipsum</p>
                                      {item.vehicle && (
                                        <div className={css['appointments-body-1']}>
                                          {item.vehicle.vehicleName && (
                                            <div>
                                              <p className={css['title-p']}>{t('vehicle')}</p>
                                              <p>{item.vehicle.vehicleName}</p>
                                            </div>
                                          )}
                                          {item.vehicle.patent && (
                                            <div>
                                              <p className={css['title-p']}>{t('patent')}</p>
                                              <p>{item.vehicle.patent}</p>
                                            </div>
                                          )}
                                          {item.vehicle.chassi && (
                                            <div>
                                              <p className={css['title-p']}>{t('chassis')}</p>
                                              <p>{item.vehicle.chassi}</p>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      <div className={css['appointments-body-2']}>
                                        {item.deliveryDate && (
                                          <div>
                                            <p className={css['title-p']}>{t('date_done')}</p>
                                            <p>{dateIso2Br(item.deliveryDate)}</p>
                                          </div>
                                        )}
                                        {item.dealer && (
                                          <div>
                                            <p className={css['title-p']}>{t('concessionaire')}</p>
                                            <p>{item.dealer.name}</p>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  }
                                >
                                  <div className={css['details-record']}>
                                    {item.dealer && (
                                      <div className={css['appointments-body-1']}>
                                        <div>
                                          <p className={css['title-p']}>{t('address')}</p>
                                          <p>{item.dealer.address.street}</p>
                                        </div>
                                        <div>
                                          <p className={css['title-p']}>{t('telephone')}</p>
                                          <p>{item.dealer.phone}</p>
                                        </div>
                                      </div>
                                    )}
                                    {item.details && (
                                      <div className={css['appointments-body-2']}>
                                        <div>
                                          <p className={css['title-p']}>{t('detail')}</p>
                                          <p>{item.details}</p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </ExpandedContent>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className={css['my-shifts__container-initialize']}>
                          <Title className={css['my-shifts__title']} variant="2">
                            {t('record_title')}
                          </Title>
                          <div className={css['no-shifts']}>
                            <p className={css['p-no-shifts']}>{t('no_record')}</p>
                            <p className={css['p-scheduled-shifts']}>{t('record_message')}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={css['my-shifts__container-initialize']}>
                      <div className={css['no-shifts']}>
                        <p className={css['no-vehicles-p']}>{t('no_vehicles')}</p>
                        <p>{t('no_vehicles_message')}</p>
                        <div className={css['buttons-add']}>
                          <Button onClick={() => updatePageParam('section', 'vehicles', router)} disabled={loading}>
                            {t('add_vehicle_button')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <div className={css['my-shifts__container']}>
                <ProgressStepper
                  listSteps={state.listSteps}
                  currentStep={currentStep}
                  onNext={handleNext}
                  onPrevious={handlePrevious}
                />
                <span className={css['content']}>
                  {t('content')}
                  <span className={css['content-vehicle']}>
                    {state?.selectedVehicleName || state?.selectedVehicleInfo?.name || t('vehicle_not_selected')}
                  </span>
                </span>
              </div>
              <div className={css['steps-result__container']}>
                {renderStepContent(listSteps[currentStep]?.name, listSteps[currentStep]?.type)}
                <div className={css['cancel_button']}>
                  <AnchorLink
                    className={css['confirm_anchor']}
                    linkLabel={t('label_cancel_appointment')}
                    hasArrow={false}
                    onClick={openModal}
                  />
                </div>
              </div>
            </>
          )}
          {isModalOpen && !loading && (
            <Modal className={css['modal-container']} isOpen={isModalOpen} onClose={closeModal}>
              <Title variant={2}>{t('title_modal')}</Title>
              <div className={css['modal-container-content']}>{t('content_modal')}</div>
              <div className={css['modal-container-buttons']}>
                <Button onClick={closeModal} color="ghost-2">
                  {t('label_button_cancel_modal')}
                </Button>
                <Button onClick={handleCancelAppointment}>{t('label_button_confirm_modal')}</Button>
              </div>
            </Modal>
          )}

          {isCancelModalOpen && !loading && (
            <Modal
              className={css['modal-container']}
              isOpen={isCancelModalOpen}
              onClose={() => setIsCancelModalOpen(false)}
            >
              <Title variant={2}>{t('title_modal')}</Title>
              <div className={css['modal-container-content']}>{t('content_modal')}</div>
              <div className={css['modal-container-buttons']}>
                <Button color="ghost-2" disabled={loading} onClick={() => setIsCancelModalOpen(false)}>
                  {t('label_button_cancel')}
                </Button>
                <Button
                  disabled={loading}
                  onClick={() => {
                    fetchCancelAppointment();
                  }}
                >
                  {t('label_button_confirm')}
                </Button>
              </div>
            </Modal>
          )}

          {isLegalTextOpen && (
            <Modal
              className={css['modal-container-legal']}
              isOpen={isLegalTextOpen}
              onClose={() => setIsLegalTextOpen(false)}
            >
              <Title variant={2}>{t('legal_title')}</Title>
              <div className={css['modal-container-content-legal']}>{t('legal_text')}</div>
            </Modal>
          )}
        </>
      )}
    </div>
  );
}

export default MyShifts;
