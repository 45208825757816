/* eslint-disable max-len */
import React from 'react';
import i18n from 'utils/i18n';
import CategoryTabs from 'components/molecules/category-tabs';
import CarDetail from 'components/molecules/car-detail';
import CarDetailSimulation from 'components/molecules/car-detail-simulation';

// loading the sass style fot the component
import css from './styles.module.scss';
import SectionHeader from 'components/molecules/section-header';
import CardsList from 'components/molecules/cards-list';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import Button from 'components/atoms/button';
import WaveDivider from 'components/atoms/wave-divider';

import InteractiveVideoContent from 'components/molecules/interactive-video-content';

/**
 * Organism ToyotaCicleCars
 *
 * <!-- TODO: add a description here! -->
 */
function ToyotaCicleCars(props) {
  const t = i18n.useTranslations('components.toyota-cicle-cars');

  const {
    className = '',
    children,
    headerData,
    locale,
    variant = null,
    carVersionsData = [],
    vehicleMedia = null,
    onlyDisplayBasePrice = false,
    showCardList = true,
    showSpecsSection = false,
    showToyotaCicleBanner = false,
    templateData,
    ...other
  } = props;

  const [content, setContent] = React.useState([]);

  const isVerticalTemplate = variant === 'vertical';

  const listTabs = [];
  const listContent = [];

  const isTASA = locale === 'es-AR';

  const [selectedTab, setSelectedTab] = React.useState(carVersionsData[0]?.id);

  const year = carVersionsData[0]?.year;
  const model = carVersionsData[0]?.model;

  const themeMap = {
    'gray-7': 'ghost-3',
    'blue-1': 'ghost-4',
    'blue-2': 'ghost-5'
  };
  const buttonTheme = themeMap[templateData?.bgColorSpecs] || 'ghost';

  carVersionsData.map((version) => {
    listTabs.push({
      label: version.name,
      id: version.id
    });
    listContent.push({
      ...version,
      label: version.name
    });
  });

  function onChange(id) {
    setSelectedTab(id);
  }

  function loadDisplay() {
    const content = listContent.find((item) => {
      return selectedTab === item?.id;
    });

    setContent(content);
  }

  React.useEffect(() => {
    loadDisplay();
  }, [selectedTab]);

  return (
    <>
      <Wrapper
        data-variant={variant}
        spacingTop={isVerticalTemplate ? '6' : null}
        spacingBottom={isVerticalTemplate ? '6' : null}
        centerContent={isVerticalTemplate || false}
        className={`${css['organism__toyota-cicle-cars-container']} ${className}`}
        {...other}
      >
        <Section className={css['header-wrapper']}>
          <SectionHeader title={t('known_versions')} description={{ value: `${model} ${!isTASA ? year : ''}`  }} />
          <CategoryTabs tabs={listTabs} selected={selectedTab} className={css['header-tabs']} onChange={onChange} />
        </Section>
        {/* THIS SECTION REFERS TO ALL THE CONTENTS OF THE TABS  */}
        {/* TAB CONTENT BEGIN =================================  */}
        <Section>
          <div key={content?.id} className={css['display']}>
            {isVerticalTemplate ? (
              <CarDetailSimulation version={content} locale={locale} templateData={templateData} />
            ) : (
              <CarDetail
                version={content}
                vehicleMedia={vehicleMedia}
                locale={locale}
                model={model}
                onlyDisplayBasePrice={onlyDisplayBasePrice}
                showToyotaCicleBanner={showToyotaCicleBanner}
                templateData={templateData}
              />
            )}
            {content?.specs?.length > 0 && showCardList && (
              <CardsList className={css['cards-list-container']} cardsData={content?.specs?.slice(0, 4)} />
            )}
          </div>
          {/* TAB CONTENT END ===================================  */}
        </Section>
      </Wrapper>

      {showSpecsSection && content?.specs && (
        <>
          <WaveDivider color="white" orientation="left" />
          <Wrapper spacingTop="7" spacingBottom="5" centerContent bgColor={templateData?.bgColorSpecs || 'black'}>
            <Section>
              <SectionHeader title={templateData?.cardsListTitle} />
              <CardsList variant="type-2" cardsData={content?.specs?.slice(0, 4)} />
            </Section>
          </Wrapper>
          {content?.pdf_url?.url && (
            <Wrapper spacingTop="0" spacingBottom="4" centerContent bgColor={templateData?.bgColorSpecs || 'black'}>
              <Section>
                <Button color={buttonTheme} link={content?.pdf_url?.url}>
                  CONFIRA AS ESPECIFICAÇÕES
                </Button>
              </Section>
            </Wrapper>
          )}
          {content?.motorVideo && (
            <InteractiveVideoContent
              bgColor={templateData?.bgColorSpecs || 'black'}
              spacingBottom={6}
              mediaVideo={content?.motorVideo}
              showDivider={true}
              buttonColor={'ghost'}
              buttonLabel={templateData?.videoSectionPlayButtonText}
              contentText={templateData?.videoSectionDescription}
              containerVideoSize={'small'}
            />
          )}
        </>
      )}
    </>
  );
}

export default ToyotaCicleCars;
