import React from 'react';
import Section from 'components/utils/section';
import ContentDivider from 'components/atoms/content-divider';
import { dateIso2Br } from 'utils/functions';

// loading the sass style fot the component
import css from './styles.module.scss';
import Title from 'components/atoms/title';

/**
 * Organism NewsHeader
 *
 * <!-- TODO: add a description here! -->
 */
function NewsHeader(props) {
  const { newsData } = props;

  return (
    <Section className={css['molecule__news-header-container']}>
      <div className={css['header']}>
        <div className={css['header-1']}>
          {newsData?.newsCategories && newsData?.newsCategories.map((item, index) => (
            <div className={css['card__category']} key={index}>
              <p key={index}>{item?.name}</p>
            </div>
          ))}
          {newsData?.releaseDate &&
          <div className={css['header-2']}>
          <p>{dateIso2Br(newsData?.releaseDate)}</p>
          </div>}
        </div>
        <ContentDivider />
        </div>
        {newsData?.title && <Title level={1} variant={4}>{newsData?.title}</Title>}
    </Section>
  );
}

export default NewsHeader;
