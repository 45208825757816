const findAll = () => {
  return `
    query servicesByLanguage($locale: String!) {
      services (locale: $locale) {
        id
        title
        content {
          icon {
            url
            title
          }
        }
        page {
          id
          fullpath
        }
      }
    }`;
};

const findByPage = () => {
  return `query service($pageId: String!) {
    serviceByPage (pageId: $pageId) {
      id
      title
      pageId
      description
      content {
        icon {
          url
          title
        }
        description
        media {
          url
          title
          mediaType{
            title
          }
        }
      }
      page {
        fullpath
      }
    }
  }`;
};

const getServiceItemCategoriesByPage = () => {
  return `query service($pageId: String!, $installmentsNumber: Float) {
    serviceByPage (pageId: $pageId) {
      serviceItemCategories {
        id
        title
        description
        media {
          url
          title
          mediaType {
            title
          }
        }
        service {
          id
        }
        serviceItems (onlyForAppointments : false) {
          id
          title
          subtitle
          description
          contents {
            title
            description
          }
          price (installmentsNumber: $installmentsNumber){
            value
            endValidity
            startValidity
            installment {
              quantity
              value
            }
          }
        }
      }
    }
  }`;
};

const Service = {
  getServiceItemCategoriesByPage,
  findAll,
  findByPage
};

export default Service;
