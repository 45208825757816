import React, { useState, useEffect, useMemo } from 'react';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import Title from '../../atoms/title';
import FormCheckbox from 'components/atoms/form-checkbox';
import Gallery from 'components/organisms/gallery';
import Button from 'components/atoms/button';
import JSZip from 'jszip';
import i18n from 'utils/i18n';
import css from './styles.module.scss';

function GalleryDownload(props) {
  const {
    className = '',
    title,
    level = 2,
    variant = 4,
    spacingTop = 0,
    spacingBottom = 0,
    galleryData = [],
    backgroundModal = 'light',
    ...other
  } = props;

  const t = i18n.useTranslations('components.gallery-download');
  const [checkboxes, setCheckboxes] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);

  const initialVisibleCount = 4;

  useEffect(() => {
    if (galleryData.length) {
      const initialCheckboxes = galleryData.reduce((acc, gallery, index) => {
        acc[gallery.id || index] = false;
        return acc;
      }, {});
      setCheckboxes(initialCheckboxes);
      setSelectAll(false);
    }
  }, [galleryData]);

  const handleCheckboxChange = (id, checked) => {
    setCheckboxes((prevState) => ({
      ...prevState,
      [id]: checked
    }));
  };

  const handleSelectAllChange = (checked) => {
    setSelectAll(checked);
    setCheckboxes((prevState) => {
      const newCheckboxes = {};
      Object.keys(prevState).forEach((key) => {
        newCheckboxes[key] = checked;
      });
      return newCheckboxes;
    });
  };

  const selectedCount = useMemo(() => {
    return Object.values(checkboxes).filter(Boolean).length;
  }, [checkboxes]);

  const isDisabled = selectedCount === 0;

  const countAllImages = galleryData.length;
  const imagesToDisplay = showAllImages ? galleryData : galleryData.slice(0, initialVisibleCount);

  const handleToggleImages = () => {
    setShowAllImages(!showAllImages);
  };

  const handleDownloadImage = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
      }
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = url.substring(url.lastIndexOf('/') + 1);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const handleDownloadSelectedImages = async () => {
    if (galleryData.length === 0) {
      console.warn('No images available to download.');
      return;
    }

    const zip = new JSZip();
    const folder = zip.folder('images');

    const imagePromises = galleryData.map((gallery, index) => {
      const isSelected = checkboxes[gallery.id || index];
      const url = gallery.url;

      if (isSelected && url) {
        return fetch(url)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
            }
            return response.blob();
          })
          .then((blob) => {
            const filename = url.substring(url.lastIndexOf('/') + 1);
            folder.file(filename, blob);
          })
          .catch((error) => {
            console.error(`Error processing image ${url}:`, error);
          });
      }
      return Promise.resolve();
    });

    await Promise.all(imagePromises);

    try {
      const content = await zip.generateAsync({ type: 'blob' });
      if (content.size > 0) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(content);
        link.download = 'images.zip';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } else {
        console.error('Generated ZIP is empty.');
      }
    } catch (error) {
      console.error('Error generating or downloading ZIP file:', error);
    }
  };

  return (
    <Wrapper
      className={`${css['organism__gallery-download-container']} ${className}`}
      {...other}
      data-spacing-top={spacingTop}
      data-spacing-bottom={spacingBottom}
    >
      <Section>
        <Title level={level} variant={variant} className={css['gallery-title']}>
          {!title ? t('title-gallery-download') : title}
        </Title>
        <div className={css['buttons-container']}>
          <FormCheckbox
            className={css['checkbox-select-all']}
            label={t('select-all')}
            checked={selectAll}
            onChange={(e) => handleSelectAllChange(e.target.checked)}
          />
          <Button className={css['button-download-all']} disabled={isDisabled} onClick={handleDownloadSelectedImages}>
            {t('download-selection')} {selectedCount > 0 && `(${selectedCount})`}
            <img src="/images/icons/icon-download.svg" alt="icon download" />
          </Button>
        </div>
        <div className={css['gallery-list-content']}>
          <ul className={css['gallery-list']}>
            {imagesToDisplay.map((gallery, key) => (
              <li key={gallery.id || key}>
                <FormCheckbox
                  className={css['checkbox-select-image']}
                  label={t('select-image')}
                  checked={checkboxes[gallery.id || key] || false}
                  onChange={(e) => handleCheckboxChange(gallery.id || key, e.target.checked)}
                />
                <Gallery
                  size="small"
                  isDownload={true}
                  hasVehicleTitle={false}
                  coverImage={gallery?.url}
                  opacity="false"
                  className={css['gallery-list-item']}
                  galleryData={galleryData}
                  index={key}
                  backgroundModal={backgroundModal}
                />
                <Button className={css['button-download-single']} onClick={() => handleDownloadImage(gallery?.url)}>
                  {t('single-download-button')}
                  <img src="/images/icons/icon-download.svg" alt="icon download" />
                </Button>
              </li>
            ))}
          </ul>
          {countAllImages > 4 && (
            <Button data-color="light" className={css['button-see-all-images']} onClick={handleToggleImages}>
              {showAllImages ? t('see-less-images') : t('see-all-images')}
              <img src="/images/icons/icn-arrow-down.svg" alt="icon download" />
            </Button>
          )}
        </div>
      </Section>
    </Wrapper>
  );
}

export default GalleryDownload;
