import React, { useEffect, useRef, useState } from 'react';
import i18n from 'utils/i18n';
import { useRouter } from 'next/router';

import { removeDuplicateItems } from 'utils/forms';
import { dateIso2Br } from 'utils/functions';

import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import Loader from 'components/atoms/loader';
import SubmitSearchButton from 'components/atoms/submit-search-button';
import Wrapper from 'components/atoms/wrapper';
import Pagination from 'components/molecules/pagination';
import SearchResultList from 'components/organisms/search-result-list';
import Section from 'components/utils/section';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism NewsSearch
 *
 * <!-- TODO: add a description here! -->
 */
function NewsSearch(props) {
  const t = i18n.useTranslations('components.news-search');
  const router = useRouter();
  const {
    className = '',
    children,
    categories = [],
    currentPage = 0,
    itemsPerPage = 0,
    newsData = [],
    totalPages = 0,
    ...other
  } = props;

  const [dataResultListSearch, setDataResultListSearch] = useState([]);
  const [statusSearch, setStatusSearch] = useState('showDefaultNews');
  const [loading, setLoading] = useState(true);
  const [dinamicCurrentPage, setDinamicCurrentPage] = useState(1);
  const [dinamicTotalPages, setDinamicTotalPages] = useState();
  const [totalItems, setTotalItems] = useState();
  const previousSearchRef = useRef('');
  const previousCategoryRef = useRef('');

  const validationSchemaConfig = {
    search: yup.string().test('is-no-empty', t('message_error_min_char_search'), (value) => {
      return value.trim() !== '';
    }),
    category: yup.string()
  };

  const validationSchema = yup.object().shape(validationSchemaConfig);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      search: '',
      category: ''
    }
  });

  const watchSearch = watch('search');
  const watchCategory = watch('category');

  useEffect(() => {
    if (watchSearch === '' && watchCategory === '') {
      setStatusSearch('showDefaultNews');
      setDataResultListSearch(formatData(newsData));
      setLoading(false);
    } else {
      searchToken();
    }
  }, [
    currentPage,
    dinamicCurrentPage,
    watchCategory,
    statusSearch
  ]);

  useEffect(() => {
    setDinamicCurrentPage(1);
    updateUrlWithPageNumber('');
  }, [watchCategory]);

  const formatData = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return data.map((item) => {
      return {
        date: dateIso2Br(item.releaseDate),
        media: {
          url: item.highlightMedia.url
        },
        title: item.title,
        category: item.newsCategories[0].name,
        content: item.description,
        link: item.page.fullpath,
        linkLabel: t('link_label_section_card')
      };
    });
  };

  const searchToken = async () => {
    setLoading(true);
    try {
      const newsCategoriesIds = watchCategory === '' ? null : watchCategory;
      const skip = (dinamicCurrentPage - 1) * itemsPerPage;
      const take = itemsPerPage;

      let url = `/api/news?token=${encodeURIComponent(watchSearch)}&releaseDateOrder=desc&skip=${skip}&take=${take}`;

      if (newsCategoriesIds) {
        url += `&newsCategoriesIds=${encodeURIComponent(newsCategoriesIds)}`;
      }

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Search error: ${response.statusText}`);
      }

      const resultData = await response.json();
      const newsData = resultData?.searchNewsByFilters?.news || [];
      const resultTotalItems = resultData?.searchNewsByFilters?.total;
      const newResultData = removeDuplicateItems(newsData);

      setDataResultListSearch(formatData(newResultData));
      setDinamicTotalPages(Math.ceil(resultTotalItems / itemsPerPage));
      setTotalItems(resultTotalItems);
      setStatusSearch('showListSearch');
    } catch (error) {
      console.error('Error when searching for news:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeCategory = (ev) => {
    setValue('category', ev.target.value);
  };

  const updateUrlWithPageNumber = (pageNumber) => {
    if (!router) {
      return;
    }
    const url = router.asPath;
    const hasPageNumber = url.match(/\d/g);
    const newUrl = hasPageNumber ? url.replace(/\d/g, pageNumber) : `${url}/${pageNumber}`;

    router.push(newUrl);
  };

  const dinamicPagination = (pageNumber) => {
    setDinamicCurrentPage(pageNumber);
    updateUrlWithPageNumber(pageNumber);
  };

  const defaultPagination = (pageNumber) => {
    updateUrlWithPageNumber(pageNumber);
  };

  const renderPagination = (totalPages, currentPage, onPageChange) =>
    totalPages > 1 && <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={onPageChange} />;

  const onSubmit = (data) => {
    const newSearch = data.search;
    const newCategory = data.category;

    if (newSearch.trim() === '') {
      return setDataResultListSearch([]);
    }

    if (newSearch === previousSearchRef.current && newCategory === previousCategoryRef.current) {
      return;
    }

    if (newSearch !== previousSearchRef.current || newCategory !== previousCategoryRef.current) {
      setDinamicCurrentPage(1);
      updateUrlWithPageNumber('');
    }

    searchToken();
    previousSearchRef.current = data.search;
    previousCategoryRef.current = data.category;
  };

  const onError = (error) => {
    console.log('Error: ', error);
  };

  const handleCloseClick = () => {
    setValue('search', '');
    setValue('category', '');
    previousSearchRef.current = '';
    previousCategoryRef.current = '';
    updateUrlWithPageNumber('');
    setStatusSearch('showDefaultNews');
  };

  return (
    <Wrapper className={`${css['organism__news-search-container']} ${className}`} {...other}>
      <Section>
        <div className={`${css['form-container']}`}>
          <button
            className={watchSearch === '' ? css['close-icon-empty'] : css['close-icon']}
            onClick={() => handleCloseClick()}
          ></button>
          <form className={css['search-form']} onSubmit={handleSubmit(onSubmit, onError)}>
            <div className={css['search']}>
              <FormInput
                displayLabel={false}
                id="search"
                type="search"
                placeholder={t('placeholder_search_input')}
                className={css['search-form__input']}
                dataRegister={register('search')}
                dataErrors={errors['search']}
              />
              <SubmitSearchButton disabled={watchSearch === previousSearchRef.current} />
            </div>
            <FormSelect
              className={css['search-form__select']}
              dataRegister={register('category')}
              onChange={(ev) => handleChangeCategory(ev)}
            >
              <option key={t('select_all_categories')} value="">
                {t('select_all_categories')}
              </option>
              {categories.length > 0 &&
                categories?.map((category, index) => {
                  return (
                    <option key={index} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
            </FormSelect>
          </form>
        </div>
        {loading && (
          <div className={css['loader-container']}>
            <Loader />
          </div>
        )}
        {!loading && (
          <>
            <SearchResultList
              className={css['section-list-container']}
              type="news"
              totalItems={totalItems}
              data={dataResultListSearch}
              token={watchSearch}
              removeResultData={watchSearch !== previousSearchRef.current || watchSearch === '' ? true : false}
            />
            {statusSearch === 'showListSearch' &&
              renderPagination(dinamicTotalPages, dinamicCurrentPage, dinamicPagination)}
            {statusSearch === 'showDefaultNews' && renderPagination(totalPages, currentPage, defaultPagination)}
          </>
        )}
      </Section>
    </Wrapper>
  );
}

export default NewsSearch;
